import { writeClipboard } from 'biz/index'
import { Stack, Typography } from '@mui/material'
import { IconCopy } from '@tabler/icons'
import { ReactNode } from 'react'

interface IProps {
    children: ReactNode
    target?: string
    message?: string
}

export default function CopiableText({ children, target, message }: IProps) {
    return (
        <Stack
            spacing="3px"
            alignItems="center"
            direction="row"
            sx={{ cursor: 'pointer' }}
            onClick={() =>
                writeClipboard(
                    target ||
                        (typeof children === 'string'
                            ? children
                            : 'The `target` prop is undefined'),
                    message,
                )
            }
        >
            {typeof children === 'string' ? (
                <Typography>{children}</Typography>
            ) : (
                children
            )}
            <IconCopy stroke={1.5} size="20px" />
        </Stack>
    )
}
