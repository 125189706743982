import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'
import Breadcrumbs from 'ui-component/extended/Breadcrumbs'
import Header from './Header'
import navigation from 'menu-items'
import { drawerWidth } from 'store/constant'
import { SET_MENU } from 'store/actions'
import { IconChevronRight } from '@tabler/icons'
import Customization from '../Customization'

const Main = styled('main', {
    shouldForwardProp: prop => prop !== 'open' && prop !== 'theme',
})(({ theme }) => ({
    ...theme.typography.mainContent,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
    },
    marginRight: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.theme.grey50,
}))

export default function SubLayout() {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

    // Handle left drawer
    const leftDrawerOpened = useSelector(state => state.customization.opened)
    const dispatch = useDispatch()
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    }

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.theme.paper,
                    transition: leftDrawerOpened
                        ? theme.transitions.create('width')
                        : 'none',
                    borderBottom: '1px solid',
                    borderBottomColor: theme.palette.theme.grey300,
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* main content */}
            <Main
                theme={theme}
                sx={{
                    mt: '84px',
                    height: 'calc(100vh - 84px)',
                    overflow: 'auto',
                }}
            >
                {/* breadcrumb */}
                <Breadcrumbs
                    separator={IconChevronRight}
                    navigation={navigation}
                    icon
                    title
                    rightAlign
                />
                <Outlet />
            </Main>
            <Customization />
        </Box>
    )
}
