export const channels = [
    {
        created_at: '2022-03-11T11:15:15.199705+00:00',
        updated_at: '2022-03-15T13:53:22.656308+00:00',
        type: 'criteo',
        category: 'media',
        name: '크리테오',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/criteo/media-criteo.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/criteo/criteo_icon.png',
        madup_account: 'media@madup.com',
    },
    {
        created_at: '2022-03-11T11:21:42.218540+00:00',
        updated_at: '2022-03-15T13:55:52.408007+00:00',
        type: 'remerge',
        category: 'media',
        name: '리머지',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/remerge/media-remerge.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/remerge/remerge_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-03-11T11:11:38.948247+00:00',
        updated_at: '2022-03-15T13:45:11.331430+00:00',
        type: 'apple_search',
        category: 'media',
        name: '애플 서치 애즈',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/apple_search/media-apple.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/apple_search/apple_icon.png',
        madup_account: 'ad@madit.kr',
    },
    {
        created_at: '2022-03-15T13:39:45.325877+00:00',
        updated_at: '2022-03-15T13:40:12.865109+00:00',
        type: 'kakao_brand',
        category: 'media',
        name: '카카오 브랜드',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/kakao_brand/media-kakao.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/kakao_brand/kakao_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-03-11T09:43:26.025156+00:00',
        updated_at: '2022-09-07T14:36:27.481242+00:00',
        type: 'facebook',
        category: 'media',
        name: '페이스북 애즈',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/facebook/media-facebook.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/facebook/facebook_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-03-11T11:12:59.601603+00:00',
        updated_at: '2022-03-15T13:48:35.705522+00:00',
        type: 'twitter',
        category: 'media',
        name: '트위터',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/twitter/media-twitter.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/twitter/twitter_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-03-11T11:02:59.187983+00:00',
        updated_at: '2022-03-15T13:28:34.932783+00:00',
        type: 'google',
        category: 'media',
        name: '구글 애즈',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/google/media-googleads.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/google/google_icon.png',
        madup_account: 'madup_madit@madup.com',
    },
    {
        created_at: '2022-05-23T11:23:16.143000+00:00',
        updated_at: '2022-05-23T11:23:16.143000+00:00',
        type: 'mobon',
        category: 'media',
        name: '모비온',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/mobon/media-mobon.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/mobon/mobon_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-03-11T11:04:48.330098+00:00',
        updated_at: '2022-03-15T13:33:42.358479+00:00',
        type: 'naver_keyword',
        category: 'media',
        name: '네이버 검색광고',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/naver_keyword/media-naversearchads.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/naver_keyword/naver_icon.png',
        madup_account: 'wooddd12@madit.kr',
    },
    {
        created_at: '2022-03-11T11:22:38.790939+00:00',
        updated_at: '2022-03-15T13:58:28.310020+00:00',
        type: 'moloco',
        category: 'media',
        name: '몰로코',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/moloco/media-moloco.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/moloco/moloco_icon.png',
        madup_account: 'ad@madup.com',
    },
    {
        created_at: '2022-03-11T11:18:48.350216+00:00',
        updated_at: '2022-03-15T13:54:22.336448+00:00',
        type: 'rtbhouse',
        category: 'media',
        name: 'RTB 하우스',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/rtbhouse/media-rtb.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/rtbhouse/rtbhouse_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-03-11T11:24:13.050623+00:00',
        updated_at: '2022-03-15T14:01:24.622626+00:00',
        type: 'appsflyer',
        category: 'tracker',
        name: '앱스플라이어',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/appsflyer/tracker-appsflyer.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/appsflyer/appsflyer_icon.png',
        madup_account: 'ad@madup.com',
    },
    {
        created_at: '2022-03-11T11:06:47.654421+00:00',
        updated_at: '2022-03-15T13:36:41.516395+00:00',
        type: 'kakao_keyword',
        category: 'media',
        name: '카카오 키워드',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/kakao_keyword/media-kakao.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/kakao_keyword/kakao_icon.png',
        madup_account: 'ad@madit.kr',
    },
    {
        created_at: '2022-03-11T11:05:55.398847+00:00',
        updated_at: '2022-03-15T13:36:02.223144+00:00',
        type: 'kakao_moment',
        category: 'media',
        name: '카카오 모먼트',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/kakao_moment/media-kakao.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/kakao_moment/kakao_icon.png',
        madup_account: 'ad@madit.kr',
    },
    {
        created_at: '2022-03-11T11:13:55.737501+00:00',
        updated_at: '2022-03-15T13:49:51.843422+00:00',
        type: 'tiktok',
        category: 'media',
        name: '틱톡',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/tiktok/media-tiktok.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/tiktok/tiktok_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-06-10T17:20:10.910000+00:00',
        updated_at: '2022-06-10T17:20:13.099000+00:00',
        type: 'nosp',
        category: 'media',
        name: '네이버 오픈 세일즈 플랫폼',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/nosp/media-nosp.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/nosp/naver_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-10-19T06:18:21.203922+00:00',
        updated_at: '2022-10-19T06:18:21.203922+00:00',
        type: 'targeting_gates',
        category: 'media',
        name: '타겟팅 게이츠',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/targeting_gates/media-targeting-gates.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/targeting_gates/targeting_gates_icon.png',
        madup_account: null,
    },
    {
        created_at: '2022-12-20T01:41:57.170020+00:00',
        updated_at: '2022-12-20T01:41:57.170020+00:00',
        type: 'naver_gfa',
        category: 'media',
        name: '네이버 GFA',
        logo_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/naver_gfa/media-naver-gfa.svg',
        icon_url:
            'https://s3-ap-northeast-2.amazonaws.com/aurora-dev-public/channel/naver_gfa/naver_gfa_icon.png',
        madup_account: null,
    },
]
