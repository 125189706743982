import { useQuery } from 'react-query'
import { ManagerApi } from 'api/index'
import { Manager } from 'model'
import { QUERY_KEY } from 'constants/index'

export default function useUser({ userId, enabled = true }) {
    return useQuery<Manager>(
        [QUERY_KEY.users, userId],
        () => ManagerApi.getManager(userId),
        {
            suspense: true,
            enabled: userId > 0 && enabled,
        },
    )
}
