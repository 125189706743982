/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum PrismStatus {
    Enable = 'enable',
    Disable = 'disable',
    Wait = 'wait',
    Run = 'run',
    Fail = 'fail',
    Error = 'error',
    TimeOut = 'time_out',
    Expired = 'expired',
}
