import React, { FC, ReactNode } from 'react'

interface IProps {
    fallback?: FC<IError>
    children: ReactNode
}

export interface IError {
    errorInfo?: any
    error: any
}

export default class ErrorBoundary extends React.Component<IProps, IError> {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
    }

    render() {
        const ErrorScreen = this.props.fallback || DefaultErrorScreen
        if (this.state.errorInfo) {
            return (
                <ErrorScreen
                    error={this.state.error as any}
                    errorInfo={this.state.errorInfo}
                />
            )
        }
        return this.props.children
    }
}

function DefaultErrorScreen({ error, errorInfo }: IError) {
    return (
        <div style={{ padding: 20 }}>
            <h2>Something went wrong.</h2>
            <pre>{error && JSON.stringify(error, null, 2)}</pre>
            <details style={{ whiteSpace: 'pre-wrap' }}>
                {errorInfo.componentStack}
            </details>
        </div>
    )
}
