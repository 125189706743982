import { useQuery } from 'react-query'
import { BusinessApi } from 'api/index'
import { Business } from 'model'
import { QUERY_KEY } from 'constants/index'

export default function useBusinesses() {
    return useQuery<Business[]>(
        QUERY_KEY.businesses,
        () => BusinessApi.getBusinesses(),

        {
            suspense: true,
        },
    )
}
