import { useEffect } from 'react'
import useDarkMode from 'zustands/useDarkMode'
import { isApr, isMar } from 'biz/index'
import { loadCss, loadJs } from 'utils/lib'

export default function useSakura() {
    const { sakura } = useDarkMode()
    useEffect(() => {
        if ((isApr || isMar) && sakura) {
            loadCss('/sakura.css')
            loadJs(
                'https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js',
            ).then(() => loadJs('/sakura.js'))
        }
    }, [sakura])
}
