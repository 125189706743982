import { ReactNode, useEffect, useState } from 'react'
import { DumbFunction } from './types'

interface ICtx {
    setVisible: (visible: boolean) => void
    setTitle: any
    setDesc: any
    setOnOk: any
    setOnCancel: any
    setOkLabel: any
    setCancelLabel: any
    setOptions: any
    setCancelButtonHidden: any
}

let ctx: ICtx | null = null
type ClickHandler = (fn: DumbFunction) => void

export function Confirmer({ ConfirmComponent }) {
    const [visible, setVisible] = useState(false)
    const [title, setTitle] = useState()
    const [desc, setDesc] = useState()
    const close = () => setVisible(false)
    const [onOk, setOnOk] = useState<ClickHandler>(() => close)
    const [onCancel, setOnCancel] = useState<ClickHandler>(() => close)
    const [okLabel, setOkLabel] = useState('')
    const [cancelLabel, setCancelLabel] = useState('')
    const [options, setOptions] = useState<any>()
    const [cancelButtonHidden, setCancelButtonHidden] = useState(false)

    useEffect(() => {
        ctx = {
            setVisible,
            setTitle,
            setDesc,
            setOnOk,
            setOnCancel,
            setOkLabel,
            setCancelLabel,
            setOptions,
            setCancelButtonHidden,
        }
        return () => {
            ctx = null
        }
    }, [])

    if (!visible) {
        return null
    }

    return (
        <ConfirmComponent
            title={title}
            desc={desc}
            cancelLabel={cancelLabel}
            cancelButtonHidden={cancelButtonHidden}
            close={close}
            okLabel={okLabel}
            onCancel={onCancel}
            onOk={onOk}
            options={options}
        />
    )
}

interface IConfirm {
    title: ReactNode
    desc?: ReactNode
    onOk?: ClickHandler
    onCancel?: ClickHandler
    okLabel?: ReactNode
    cancelLabel?: ReactNode
    cancelButtonHidden?: boolean
    okSx?: any
    cancelSx?: any
    options?: any
}

export const confirm = ({
    title,
    desc,
    onOk,
    onCancel,
    okLabel = '확인',
    cancelLabel = '취소',
    cancelButtonHidden = false,
    options,
}: IConfirm) => {
    ctx?.setVisible(true)
    ctx?.setTitle(title)
    ctx?.setDesc(desc)
    ctx?.setOnOk(() => onOk)
    ctx?.setOnCancel(() => onCancel)
    ctx?.setOkLabel(okLabel)
    ctx?.setCancelLabel(cancelLabel)
    ctx?.setOptions(options)
    ctx?.setCancelButtonHidden(cancelButtonHidden)
}
