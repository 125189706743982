import { ReactNode } from 'react'

interface IProps {
    summary: ReactNode
    children: ReactNode
    open?: boolean
}

export default function Details({ summary, children, open }: IProps) {
    return (
        <details
            style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            open={open}
        >
            <summary>{summary}</summary>
            <div style={{ padding: '10px' }}>{children}</div>
        </details>
    )
}
