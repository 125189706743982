import { Button as MuiButton } from '@mui/material'
import { oneOf } from 'utils/lib'
import { ReactNode } from 'react'
import useColor from 'hooks/useColor'

interface IProps {
    className?: string
    size?: 'small' | 'large' | 'medium'
    onClick?: any
    variant?: 'outlined' | 'contained' | 'text'
    disabled?: boolean
    children?: ReactNode
    sx?: any
    color?: string
    startIcon?: ReactNode
}

export default function Button(props: IProps) {
    const variant = props.variant ?? 'outlined'
    const color = useColor()
    return (
        <MuiButton
            {...(props as any)}
            className={props.className}
            variant={variant}
            sx={{
                borderRadius: '5px',
                fontWeight: 700,
                lineHeight: '16px',
                boxShadow: 'none',
                '&:hover': {
                    borderColor: color.grey300,
                    boxShadow:
                        '0px 2px 4px -1px rgba(0,0,0,0.02),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.12)',
                },

                '&.MuiButton-sizeLarge': {
                    px: '30px',
                    height: 60,
                    fontSize: '16px',
                },
                '&.MuiButton-sizeMedium': {
                    px: '20px',
                    height: 44,
                },
                '&.MuiButton-sizeSmall': {
                    height: 32,
                    px: '10px',
                    // bgcolor: 'grey.50',
                    // border: 'none',
                    minWidth: 'fit-content',
                },

                '&.MuiButton-text': {
                    color: props.color ?? color.grey700,
                },
                '&.MuiButton-text.Mui-disabled': {
                    color: color.grey500,
                },

                '&.MuiButton-contained': {
                    color: color.paper,
                    bgcolor: props.color ?? color.grey900,
                },
                '&.MuiButton-contained.Mui-disabled': {
                    bgcolor: color.grey500,
                },

                '&.MuiButton-outlined': {
                    color: props.color ?? color.grey600,
                    borderColor:
                        oneOf([
                            [props.color === 'primary', color.main],
                            [props.color === 'error', 'error.main'],
                        ]) ?? color.grey300,
                    bgcolor: 'bg.paper',
                },
                '&.MuiButton-outlined:hover': {
                    borderColor: props.color,
                },
                '&.MuiButton-outlined.Mui-disabled': {
                    color: color.grey500,
                },
                color: color.main,
                ...props.sx,
            }}
        />
    )
}
