/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * REALTIME = auto()           # 실시간  WITHIN_TODAY = auto()       # 오늘 안에  WITHIN_TOMORROW = auto()    # 내일 안에  WITHIN_3_DAYS = auto()      # 모레 안에  WITHIN_24_HOURS = auto()    # 24시간 안에  WITHIN_48_HOURS = auto()    # 48시간 안에
 * @export
 * @enum {string}
 */

export enum TargetTime {
    Realtime = 'realtime',
    WithinToday = 'within_today',
    WithinTomorrow = 'within_tomorrow',
    Within3Days = 'within_3_days',
    Within24Hours = 'within_24_hours',
    Within48Hours = 'within_48_hours',
}
