import { useQuery } from 'react-query'
import { BusinessApi } from 'api/index'
import { QUERY_KEY } from 'constants/index'
import { Business } from 'model/business'

export default function useBusiness({ bizId }) {
    return useQuery<Business>(
        [QUERY_KEY.business, bizId],
        () => BusinessApi.getBusiness({ bizId }),
        {
            suspense: true,
            enabled: Boolean(bizId), // There is no business whose `bizId` is 0
        },
    )
}
