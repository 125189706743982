import { rest } from 'msw'
import { resolver } from './data'
import { QUERY_KEY } from 'constants/index'

const API_SERVER = process.env.REACT_APP_API_SERVER_URL
const RULE_SERVER = process.env.REACT_APP_RULE_SERVER_URL

const handler = [
    rest.get(API_SERVER + '/channels', resolver[QUERY_KEY.channels]),
    rest.get(RULE_SERVER + '/rules', resolver[QUERY_KEY.kantRules]),
    rest.get(RULE_SERVER + '/logs', resolver[QUERY_KEY.kantRuleLogs]),
]

export default handler
