/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum EditableMsgCode {
    _00 = '00',
    _0000 = '0000',
    _01 = '01',
    _02 = '02',
    _03 = '03',
    _04 = '04',
    _05 = '05',
    _06 = '06',
    _21 = '21',
    _22 = '22',
    _23 = '23',
    _24 = '24',
    _25 = '25',
    _26 = '26',
    _41 = '41',
    _42 = '42',
    _43 = '43',
    _44 = '44',
    _45 = '45',
    _46 = '46',
    _47 = '47',
    _48 = '48',
    _49 = '49',
    _50 = '50',
    _51 = '51',
    _52 = '52',
    _61 = '61',
    _62 = '62',
    _63 = '63',
    _64 = '64',
}
