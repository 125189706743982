import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import { BizCategory, BizMgrStatus } from 'model'
import {
    CATEGORY_LABEL,
    ROLE_LABEL,
    STATUS_LABEL,
    THEME,
} from 'constants/index'
import MemberChip from './MemberChip'
import StarIcon from 'ui-component/svgs/StarIcon'
import useBookmarks from 'zustands/useBookmarks'
import { append, equals, evolve, reject } from 'utils/lib'
import useUserId from 'zustands/useUserId'
import { bookmarkId } from 'biz/index'

interface IProps {
    id: number
    name: string
    image?: string
    category?: BizCategory
    role?: string
    status?: string
    onClick?: (e: any) => void
    bookmark?: boolean
}

export default function BusinessCard({
    id,
    name,
    image,
    category,
    onClick,
    role,
    status,
    bookmark = false,
}: IProps) {
    const { userId } = useUserId()
    const { set } = useBookmarks()

    const starClick = () => {
        set(
            evolve({
                pairs: bookmark
                    ? reject(equals(bookmarkId(userId, id)))
                    : append(bookmarkId(userId, id)),
            }),
        )
    }

    return (
        <>
            {role && (
                <Box sx={{ position: 'relative' }}>
                    <MemberChip
                        label={
                            status === BizMgrStatus.In
                                ? ROLE_LABEL[role]
                                : STATUS_LABEL[status ?? '']
                        }
                        sx={{
                            position: 'absolute',
                            left: '-12px',
                            top: '-20px',
                        }}
                    />
                    {status === BizMgrStatus.In && (
                        <StarIcon
                            sx={{
                                position: 'absolute',
                                right: '-12px',
                                top: '-20px',
                                cursor: 'pointer',
                            }}
                            color={
                                bookmark ? THEME.color.warningDark : undefined
                            }
                            fill={
                                bookmark ? THEME.color.warningDark : undefined
                            }
                            onClick={starClick}
                        />
                    )}
                </Box>
            )}
            <Card
                onClick={onClick}
                sx={{ cursor: 'pointer', textAlign: 'center', pt: '17px' }}
            >
                <Avatar
                    alt="logo"
                    variant="square"
                    src={image}
                    sx={{
                        width: 54,
                        height: 54,
                        m: '0 auto',
                        borderRadius: THEME.borderRadius,
                        border: '1px solid',
                        borderColor: 'grey.300',
                        bgcolor: image ? 'transparent' : undefined,
                    }}
                />
                <CardContent
                    sx={{
                        padding: '20px 5px',
                        '&:last-child': { paddingBottom: '16px' },
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        className="ellipsis"
                        sx={{ mb: 0 }}
                    >
                        {name}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        style={{ maxHeight: 37 }}
                    >
                        {category
                            ? CATEGORY_LABEL[category] || category
                            : 'N/A'}{' '}
                        ({String(id).padStart(3, '0')})
                    </Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">Share</Button>*/}
                {/*    <Button size="small">Learn More</Button>*/}
                {/*</CardActions>*/}
            </Card>
        </>
    )
}
