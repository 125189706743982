import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import MainRoutes from './MainRoutes'
import MinimalRoutes from './MinimalRoutes'
import config from 'config'
import { matched, pathMatched } from '@madup-inc/utils'
import { useEffect } from 'react'
import SubRoutes from './SubRoutes'
import PageNotFound from 'views/pages/PageNotFound'
import token from 'biz/token'
import { sStorage } from 'utils/lib'
import { TARGET_URL } from 'constants/index'
import useUserId from 'zustands/useUserId'
import useUser from 'queries/useUser'
import { ManagerType } from 'model/manager-type'

export default function Routes() {
    const accessToken = token()
    const { userId } = useUserId()
    const { data: user } = useUser({ userId, enabled: Boolean(accessToken) })
    const privatePage = pathMatched(['/', /\/basic\/.+/])
    const notAuthorized = privatePage && !accessToken
    const notAdminUser = user?.type !== ManagerType.Admin
    const navigate = useNavigate()
    const location = useLocation()

    const Page = useRoutes(
        [MainRoutes, MinimalRoutes, SubRoutes],
        config.basename,
    ) || <PageNotFound />

    useEffect(effect({ notAdminUser, notAuthorized, location, navigate }), [
        location.pathname,
    ])

    return notAuthorized ? null : Page
}

export const effect =
    ({ notAdminUser, notAuthorized, location, navigate }) =>
    () => {
        if (notAuthorized) {
            if (
                !matched(['/', '/login'], location.pathname) ||
                location.search
            ) {
                sStorage.set(TARGET_URL, location.pathname + location.search)
            }

            navigate('/login')
        }
        if (notAdminUser) {
            if (
                !matched(
                    [
                        '/basic/money',
                        '/basic/business-setting',
                        '/basic/manage-member',
                        '/basic/businesses',
                        '/404',
                        '/login',
                        '/',
                    ],
                    location.pathname,
                )
            ) {
                navigate('/')
            }
        }
    }
