import { lStorage, sStorage } from 'utils/lib'
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from 'constants/index'
import jwt from 'jwt-decode'
import dayjs from 'dayjs'

interface GetTokenReturn {
    token: string
    isLocal: boolean
}

export const getToken = (): GetTokenReturn | null => {
    const token = sStorage.get(ACCESS_TOKEN_NAME)
    if (token) {
        return { token, isLocal: false }
    }

    const localToken = lStorage.get(ACCESS_TOKEN_NAME)
    if (!localToken) {
        return null
    }

    let { exp }: any = jwt(localToken)
    if (String(exp) < String(dayjs().unix())) {
        return null
    }

    return { token: localToken, isLocal: true }
}

interface SetToken {
    accessToken: string
    refreshToken: string
    useLocal?: boolean
}

export const setToken = ({ accessToken, refreshToken, useLocal }: SetToken) => {
    const storage = useLocal ? lStorage : sStorage
    storage.set(ACCESS_TOKEN_NAME, accessToken)
    storage.set(REFRESH_TOKEN_NAME, refreshToken)
}

export default function token(): string | null {
    const result = getToken()
    if (!result) {
        return null
    }
    if (result.isLocal) {
        setToken({
            accessToken: result.token,
            refreshToken: lStorage.get(REFRESH_TOKEN_NAME),
        })
    }
    return result.token
}
