import create from 'zustand'
import { persist } from 'zustand/middleware'
import { DumbFunction } from '../types'

interface State {
    bizId: number
}

interface Store extends State {
    set: (param: State) => void
    clear: DumbFunction
}

const useBizId = create<Store>(
    persist(
        set => ({
            bizId: 0,
            set,
            clear: () => set({}, true),
        }),
        {
            name: 'biz_id', // unique name
            getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
        },
    ),
)

export default useBizId
