/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * TA = auto()         # 온도   MIN = auto()        # 일 최저 기온   MAX = auto()        # 일 최고 기온   ST = auto()         # 강수 확률   WD = auto()         # 풍속   HM = auto()         # 습도   RN = auto()         # 강수량   SD = auto()         # 적설량   DI = auto()         # 불쾌 지수
 * @export
 * @enum {string}
 */

export enum WeatherMetricType {
    Ta = 'ta',
    Min = 'min',
    Max = 'max',
    St = 'st',
    Wd = 'wd',
    Hm = 'hm',
    Rn = 'rn',
    Sd = 'sd',
    Di = 'di',
}
