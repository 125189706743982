import create from 'zustand'
import { persist } from 'zustand/middleware'
import { DumbFunction } from '../types'
import { isApr, isDec, isJan, isMar } from 'biz/index'

interface State {
    darkMode: boolean
    snowy: boolean
    sakura: boolean
    christmas: boolean
}

interface Store extends State {
    set: (param: State) => void
    clear: DumbFunction
}

const useDarkMode = create<Store>(
    persist(
        set => ({
            darkMode: isDec,
            snowy: isDec || isJan,
            christmas: isDec,
            sakura: isApr || isMar,
            set,
            clear: () => set({}, true),
        }),
        {
            name: 'darkMode', // unique name
            getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
        },
    ),
)

export default useDarkMode
