import * as React from 'react'
import { clsNms } from '@madup-inc/utils'
import { styled } from '@mui/system'
import { useSwitch, UseSwitchProps } from '@mui/base/SwitchUnstyled'

export default function UnstyledSwitch(props: UseSwitchProps) {
    const { getInputProps, checked, disabled, focusVisible } = useSwitch(props)

    const stateClasses = {
        'Switch-checked': checked,
        'Switch-disabled': disabled,
        'Switch-focusVisible': focusVisible,
    }

    return (
        <BasicSwitchRoot className={clsNms(stateClasses)}>
            <BasicSwitchThumb className={clsNms(stateClasses)} />
            <BasicSwitchInput {...getInputProps()} aria-label="Demo switch" />
        </BasicSwitchRoot>
    )
}

const BasicSwitchRoot = styled('span')(
    ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background: ${theme.palette.grey[400]};
    border-radius: 10px;
    cursor: pointer;

    &.Switch-disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &.Switch-checked {
        background: ${theme.palette.primary.main};
    }
`,
)

const BasicSwitchInput = styled('input')`
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
`

const BasicSwitchThumb = styled('span')(
    ({ theme }) => `
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;

    &.Switch-focusVisible {
        background-color: ${theme.palette.grey[500]};
        box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }

    &.Switch-checked {
        left: 22px;
        top: 3px;
        background-color: #fff;
    }
`,
)
