/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum AppsflyerRawReportType {
    NonOrganicInstalls = 'non-organic_installs',
    NonOrganicInAppEvents = 'non-organic_in-app-events',
    RetargetingConversions = 'retargeting_conversions',
    RetargetingInAppEvents = 'retargeting_in-app-events',
    NonOrganicReinstall = 'non-organic_reinstall',
    OrganicReinstall = 'organic_reinstall',
}
