import { forwardRef, ReactElement, ReactNode } from 'react'
import { useTheme } from '@mui/material/styles'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { THEME } from 'constants/index'

interface IProps {
    border?: boolean
    boxShadow?: boolean
    children: ReactNode
    content?: boolean
    contentClass?: string
    contentSX?: any
    headerSX?: any
    titleVariant?: OverridableStringUnion<
        Variant | 'inherit',
        TypographyPropsVariantOverrides
    >
    titleDivider?: boolean
    secondary?: ReactNode | string | any
    shadow?: string
    sx?: any
    title?: ReactNode | string | any
    headerBar?: ReactNode | string
    headerAtScrollText?: string
}

const MainCard = forwardRef<ReactElement, IProps>(
    (
        {
            border = false,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            titleVariant = 'h1',
            titleDivider = false,
            headerSX = {},
            secondary,
            shadow,
            sx = {},
            title,
            headerBar,
            headerAtScrollText,
            ...others
        },
        ref,
    ) => {
        const theme = useTheme()
        return (
            <Card
                // @ts-ignore
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    borderRadius: THEME.borderRadius2,
                    boxShadow: shadow,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)'
                            : 'inherit',
                    },
                    ...sx,
                }}
            >
                {headerBar && (
                    <Box
                        component="header"
                        sx={{
                            position: 'fixed',
                            width: {
                                xs: 'calc(100% - 52px)',
                                sm: 'calc(100% - 71px)',
                                md: 'calc(100% - 80px)',
                                lg: 'calc(100% - 320px)',
                                xl: 'calc(100% - 320px)',
                            },
                            zIndex: 1,
                            padding: '27px 30px 26px',
                            borderBottom: `1px solid`,
                            borderBottomColor: 'grey.300',
                            bgcolor: 'bg.paper',
                        }}
                    >
                        {typeof headerBar === 'string' ? (
                            <Typography variant="h4" sx={{ color: 'grey.600' }}>
                                {headerBar}
                            </Typography>
                        ) : (
                            headerBar
                        )}
                    </Box>
                )}
                {headerBar && <Box height="80px" />}

                {title && (
                    <CardHeader
                        sx={{
                            padding: `${
                                headerBar ? '80px' : '100px'
                            } 60px 20px`,
                            ...headerSX,
                        }}
                        title={
                            typeof title === 'string' ? (
                                <Typography variant={titleVariant}>
                                    {title}
                                </Typography>
                            ) : (
                                title
                            )
                        }
                        action={secondary}
                    />
                )}

                {/* content & header divider */}
                {titleDivider && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent
                        sx={{ padding: '20px 60px 100px', ...contentSX }}
                        className={contentClass}
                    >
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        )
    },
)

export default MainCard
