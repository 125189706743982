import ChartIcon from 'ui-component/svgs/ChartIcon'
import FilterIcon from 'ui-component/svgs/FilterIcon'
import ToolIcon from 'ui-component/svgs/ToolIcon'
import SettingIcon from 'ui-component/svgs/SettingIcon'
import DocIcon from 'ui-component/svgs/DocIcon'

const menu = {
    id: 'basic-menu',
    title: '기본 관리',
    type: 'group',
    children: [
        {
            id: 'extract',
            title: '데이터 추출',
            type: 'collapse',
            icon: FilterIcon,
            children: [
                {
                    id: 'update-channel',
                    title: '매체, 트래커 최신화',
                    type: 'item',
                    url: '/basic/extract/refresh',
                    breadcrumbs: false,
                },
            ],
        },
        {
            id: 'manage-media',
            title: '매체 운영',
            type: 'collapse',
            icon: ToolIcon,
            children: [
                {
                    id: 'ad-setting',
                    title: '광고 설정',
                    type: 'item',
                    url: '/basic/manage/ad-setting',
                    breadcrumbs: false,
                },
                {
                    id: 'setting-history',
                    title: '실행 이력',
                    type: 'item',
                    url: '/basic/manage/setting-history',
                    breadcrumbs: false,
                },
                {
                    id: 'manage-rule',
                    title: '규칙 관리',
                    type: 'item',
                    url: '/basic/manage/manage-rule',
                    breadcrumbs: false,
                },
            ],
        },
        ...(process.env.REACT_APP_ENV_TYPE === 'prd'
            ? []
            : [
                  {
                      id: 'large-creative',
                      title: '대량 생성/관리',
                      type: 'collapse',
                      icon: ChartIcon,
                      children: [
                          {
                              id: 'build-creative',
                              title: '대량생성',
                              type: 'item',
                              url: '/basic/large/build-creative',
                              breadcrumbs: false,
                          },
                          {
                              id: 'build-list',
                              title: '작업신청목록',
                              type: 'item',
                              url: '/basic/large/build-list',
                              breadcrumbs: false,
                          },
                      ],
                  },
              ]),
        {
            id: 'channels',
            title: '매체, 트래커 정보 관리',
            type: 'item',
            url: '/basic/channels',
            icon: DocIcon,
            breadcrumbs: false,
        },
        {
            id: 'money',
            title: '광고비 관리',
            type: 'item',
            url: '/basic/money',
            icon: SettingIcon,
            breadcrumbs: false,
        },
        {
            id: 'setting',
            title: '설정',
            type: 'collapse',
            icon: SettingIcon,
            children: [
                {
                    id: 'business-setting',
                    title: '광고주 정보',
                    type: 'item',
                    url: '/basic/business-setting',
                    breadcrumbs: false,
                },
                {
                    id: 'manage-member',
                    title: '사용자 관리',
                    type: 'item',
                    url: '/basic/manage-member',
                    breadcrumbs: false,
                },
            ],
        },
    ],
}

export default menu
