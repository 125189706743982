import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { forwardRef } from 'react'
import useColor from 'hooks/useColor'

export default function Tooltip({
    disabled = false,
    children,
    title,
    arrow = true,
    wrapWithForwardRef = false,
    ...props
}) {
    const color = useColor()
    const childrenNode = wrapWithForwardRef ? (
        <ForwardRef>{children}</ForwardRef>
    ) : (
        children
    )

    if (disabled) {
        return childrenNode
    }

    return (
        <MuiTooltip
            {...props}
            placement={props.placement ?? 'top'}
            title={title}
            arrow={arrow}
            componentsProps={{
                arrow: {
                    sx: { color: color.grey700 },
                },
                popper: {
                    sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                            bgcolor: color.grey700,
                        },
                    },
                },
            }}
        >
            {childrenNode}
        </MuiTooltip>
    )
}

const ForwardRef: any = forwardRef<HTMLDivElement>(
    ({ children, ...props }: any, ref) => {
        return (
            <div ref={ref} {...props}>
                {children}
            </div>
        )
    },
)
