import { createTheme } from '@mui/material/styles'

// assets
import colors from 'assets/scss/_themes-vars.module.scss'

// project imports
import componentStyleOverrides from './compStyleOverride'
import themePalette from './palette'
import themeTypography from './typography'

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export default function theme(customization, dark = false, themeColor = {}) {
    const color = colors
    const themeOption = {
        colors: color,
        heading: dark ? color.paper : color.grey900,
        paper: dark ? color.grey900 : color.paper,
        backgroundDefault: dark ? color.grey900 : color.paper,
        background: dark ? color.grey700 : color.grey100,
        darkTextPrimary: dark ? color.grey50 : color.grey700,
        darkTextSecondary: dark ? color.grey200 : color.grey500,
        textDark: dark ? color.paper : color.grey900,
        menuSelected: dark ? color.secondaryLight : color.secondaryDark,
        menuSelectedBack: dark ? color.secondaryDark : color.secondaryLight,
        divider: dark ? color.grey700 : color.grey100,
        customization,
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        themeColor: themeColor,
        shadow: dark ? '0px 4px 50px rgba(256, 256, 256, 0.2)' : color.shadow,
    }

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption, dark),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px',
                },
            },
        },
        typography: themeTypography(themeOption),
    }

    const themes = createTheme(themeOptions)
    themes.components = componentStyleOverrides(themeOption, dark)

    return themes
}
