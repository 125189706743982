import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'
import Breadcrumbs from 'ui-component/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import navigation from 'menu-items'
import { drawerWidth } from 'store/constant'
import { SET_MENU } from 'store/actions'
import { IconChevronRight } from '@tabler/icons'
import useBizId from 'zustands/useBizId'
import { parseSearchParams } from 'utils/lib'
import Customization from '../Customization'
import useColor from 'hooks/useColor'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'

const Main: any = styled('main', {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }: any) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
        },
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
        },
    }),
}))

export default function MainLayout() {
    const color = useColor()
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

    const leftDrawerOpened = useSelector(
        (state: any) => state.customization.opened,
    )
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    }

    const { bizId, set: setBizId } = useBizId()
    const location = useLocation()

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd])

    useEffect(checkBizId({ location, bizId, navigate, setBizId }), [])

    const handleKeyPress = (event: any) => {
        if (event.key === '[' && event.target.tagName !== 'INPUT') {
            handleLeftDrawerToggle()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress)
        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }
    }, [handleKeyPress])

    if (!bizId) {
        return null
    }

    // @ts-ignore
    return (
        <Box
            sx={{
                display: 'flex',
                '.MuiPaper-root::-webkit-scrollbar': {
                    bgcolor: color.grey500,
                    width: 10,
                },
                '.MuiPaper-root::-webkit-scrollbar-thumb': {
                    width: 7,
                    bgcolor: color.grey300,
                },
                '.MuiPaper-root::-webkit-scrollbar-track': {
                    bgcolor: color.grey100,
                    width: 10,
                },
            }}
        >
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: color.paper,
                    transition: leftDrawerOpened
                        ? theme.transitions.create('width')
                        : 'none',
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            <Sidebar
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />

            <Main
                theme={theme}
                open={leftDrawerOpened}
                sx={{
                    mt: '84px',
                    height: 'calc(100vh - 84px)',
                    overflow: 'auto',
                }}
            >
                <Breadcrumbs
                    separator={IconChevronRight}
                    navigation={navigation}
                    icon
                    title
                    rightAlign
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        height: 0,
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <OpenInFullIcon
                        onClick={() =>
                            navigate(
                                location.pathname + '/max' + location.search,
                            )
                        }
                        sx={{
                            cursor: 'pointer',
                            margin: '5px',
                            '&:hover': { color: 'primary.main' },
                        }}
                    />
                </Box>
                <Outlet />
            </Main>
            <Customization />
        </Box>
    )
}

export const checkBizId =
    ({ location, bizId, navigate, setBizId }) =>
    () => {
        const searchBizId = parseSearchParams(location.search).bizId
        if (searchBizId) {
            const numBizId = Number(searchBizId)
            if (Number.isInteger(numBizId)) {
                setBizId({ bizId: numBizId })
            } else {
                navigate('/basic/businesses')
            }
        } else {
            if (!bizId) {
                navigate('/basic/businesses')
            }
        }
    }
