import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useColor from 'hooks/useColor'
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography,
} from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extended/Transitions'
import UpgradePlanCard from './UpgradePlanCard'
import {
    IconBook,
    IconCopy,
    IconLogout,
    IconSearch,
    IconSettings,
    IconUser,
} from '@tabler/icons'
import useUser from 'queries/useUser'
import useUserId from 'zustands/useUserId'
import { clearStorage } from 'biz/index'
import toast from 'biz/toast'
import token from 'biz/token'
import { useTheme } from '@mui/material/styles'

const ProfileSection = () => {
    const theme: any = useTheme()
    const color = useColor()
    const customization = useSelector((state: any) => state.customization)
    const navigate = useNavigate()
    const { userId } = useUserId()

    const [sdm, setSdm] = useState(true)
    const [value, setValue] = useState('')
    const [notification, setNotification] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [open, setOpen] = useState(false)
    const { data: user } = useUser({ userId })

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null)
    const handleLogout = () => {
        clearStorage()
        window.location.assign('/login')
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index)
        handleClose(event)
        if (route && route !== '') {
            navigate(route)
        }
    }
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: color.light,
                    backgroundColor: color.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: color.main,
                        background: `${color.main}!important`,
                        color: color.light,
                        '& svg': {
                            stroke: color.light,
                        },
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                    },
                }}
                icon={
                    <Avatar
                        src={user?.img_url}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={
                    <IconSettings
                        stroke={1.5}
                        size="1.5rem"
                        color={color.main}
                    />
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }: any) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    content={false}
                                    boxShadow
                                    shadow={color.shadow}
                                >
                                    <Box sx={{ p: 2, paddingBottom: 1 }}>
                                        <Stack spacing={1}>
                                            <Stack
                                                direction="row"
                                                spacing={0.5}
                                                alignItems="center"
                                            >
                                                <Typography variant="h4">
                                                    안녕하세요!
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="h4"
                                                    sx={{ fontWeight: 400 }}
                                                >
                                                    {user?.real_name}님
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">
                                                Project{' '}
                                                <b>
                                                    {user?.type === 'admin'
                                                        ? 'Admin'
                                                        : 'Manager'}
                                                </b>
                                            </Typography>
                                        </Stack>
                                        {process.env
                                            .REACT_APP_GNB_HIDDEN_PROFILE_SECTION !==
                                            'true' && (
                                            <OutlinedInput
                                                sx={{
                                                    width: '100%',
                                                    pr: 1,
                                                    pl: 2,
                                                    my: 2,
                                                }}
                                                id="input-search-profile"
                                                value={value}
                                                onChange={e =>
                                                    setValue(e.target.value)
                                                }
                                                placeholder="Search profile options"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <IconSearch
                                                            stroke={1.5}
                                                            size="1rem"
                                                            color={
                                                                color.grey500
                                                            }
                                                        />
                                                    </InputAdornment>
                                                }
                                                aria-describedby="search-helper-text"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                }}
                                            />
                                        )}
                                        {process.env
                                            .REACT_APP_GNB_HIDDEN_PROFILE_SECTION !==
                                            'true' && <Divider />}
                                    </Box>
                                    <PerfectScrollbar
                                        style={{
                                            height: '100%',
                                            maxHeight: 'calc(100vh - 250px)',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        <Box sx={{ p: 2 }}>
                                            {process.env
                                                .REACT_APP_GNB_HIDDEN_PROFILE_SECTION !==
                                                'true' && <UpgradePlanCard />}
                                            {process.env
                                                .REACT_APP_GNB_HIDDEN_PROFILE_SECTION !==
                                                'true' && <Divider />}
                                            {process.env
                                                .REACT_APP_GNB_HIDDEN_PROFILE_SECTION !==
                                                'true' && (
                                                <Card
                                                    sx={{
                                                        bgcolor: color.light,
                                                        my: 2,
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Grid
                                                            container
                                                            spacing={3}
                                                            direction="column"
                                                        >
                                                            <Grid item>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1">
                                                                            Start
                                                                            DND
                                                                            Mode
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Switch
                                                                            color="primary"
                                                                            checked={
                                                                                sdm
                                                                            }
                                                                            onChange={e =>
                                                                                setSdm(
                                                                                    e
                                                                                        .target
                                                                                        .checked,
                                                                                )
                                                                            }
                                                                            name="sdm"
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Grid item>
                                                                        <Typography variant="subtitle1">
                                                                            Allow
                                                                            Notifications
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Switch
                                                                            checked={
                                                                                notification
                                                                            }
                                                                            onChange={e =>
                                                                                setNotification(
                                                                                    e
                                                                                        .target
                                                                                        .checked,
                                                                                )
                                                                            }
                                                                            name="sdm"
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            )}
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor:
                                                        color.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down(
                                                        'md',
                                                    )]: {
                                                        minWidth: '100%',
                                                    },
                                                    '& .MuiListItemButton-root':
                                                        {
                                                            mt: 0.5,
                                                        },
                                                }}
                                            >
                                                {process.env
                                                    .REACT_APP_GNB_HIDDEN_PROFILE_SECTION !==
                                                    'true' && (
                                                    <ListItemButton
                                                        sx={{
                                                            borderRadius: `${customization.borderRadius}px`,
                                                        }}
                                                        selected={
                                                            selectedIndex === 0
                                                        }
                                                        onClick={event =>
                                                            handleListItemClick(
                                                                event,
                                                                0,
                                                                '/user/account-profile/profile1',
                                                            )
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings
                                                                stroke={1.5}
                                                                size="1.3rem"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    Account
                                                                    Settings
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                )}
                                                {process.env
                                                    .REACT_APP_GNB_HIDDEN_PROFILE_SECTION !==
                                                    'true' && (
                                                    <ListItemButton
                                                        sx={{
                                                            borderRadius: `${customization.borderRadius}px`,
                                                        }}
                                                        selected={
                                                            selectedIndex === 1
                                                        }
                                                        onClick={event =>
                                                            handleListItemClick(
                                                                event,
                                                                1,
                                                                '/user/social-profile/posts',
                                                            )
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <IconUser
                                                                stroke={1.5}
                                                                size="1.3rem"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Grid item>
                                                                        <Typography variant="body2">
                                                                            Social
                                                                            Profile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Chip
                                                                            label="02"
                                                                            size="small"
                                                                            sx={{
                                                                                bgcolor:
                                                                                    theme
                                                                                        .palette
                                                                                        .warning
                                                                                        .dark,
                                                                                color: theme
                                                                                    .palette
                                                                                    .background
                                                                                    .default,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                )}
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`,
                                                    }}
                                                    selected={
                                                        selectedIndex === 4
                                                    }
                                                    onClick={() =>
                                                        window.open(
                                                            'https://help.aurora.lever.me',
                                                            '_blank',
                                                        )
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <IconBook
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                이용 가이드
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`,
                                                    }}
                                                    selected={
                                                        selectedIndex === 4
                                                    }
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                Logout
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                                {process.env
                                                    .REACT_APP_ENV_TYPE !==
                                                    'prd' && (
                                                    <ListItemButton
                                                        sx={{
                                                            borderRadius: `${customization.borderRadius}px`,
                                                        }}
                                                        selected={
                                                            selectedIndex === 4
                                                        }
                                                        onClick={() =>
                                                            window.navigator.clipboard
                                                                .writeText(
                                                                    token() ||
                                                                        'token not found',
                                                                )
                                                                .then(() =>
                                                                    toast.success(
                                                                        'accessToken copied',
                                                                    ),
                                                                )
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <IconCopy
                                                                stroke={1.5}
                                                                size="1.3rem"
                                                            />
                                                        </ListItemIcon>

                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    Copy
                                                                    accessToken
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                )}
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    )
}

export default ProfileSection
