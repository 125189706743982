import { Chip } from '@mui/material'
import React from 'react'
import { BizMgrRole } from 'model'
import { ROLE_LABEL } from 'constants/index'

interface IProps {
    label: string
    sx?: any
}

const COLOR = {
    [ROLE_LABEL[BizMgrRole.Master]]: 'primary.main',
    [ROLE_LABEL[BizMgrRole.Member]]: '#FF8A1E',
    [ROLE_LABEL[BizMgrRole.Viewer]]: 'grey.700',
}

const BGCOLOR = {
    [ROLE_LABEL[BizMgrRole.Master]]: '#E3F2FD',
    [ROLE_LABEL[BizMgrRole.Member]]: '#FFEED5',
    [ROLE_LABEL[BizMgrRole.Viewer]]: 'grey.200',
}

const BORDER_COLOR = {
    [ROLE_LABEL[BizMgrRole.Master]]: '#E3F2FD',
    [ROLE_LABEL[BizMgrRole.Member]]: '#FFEED5',
    [ROLE_LABEL[BizMgrRole.Viewer]]: 'grey.200',
}

export default function MemberChip({ label, sx }: IProps) {
    return (
        <Chip
            label={label}
            sx={{
                height: '22px',
                color: COLOR[label] || 'grey.700',
                bgcolor: BGCOLOR[label] || 'bg.paper',
                fontWeight: 'bold',
                border: '1px solid',
                borderColor: BORDER_COLOR[label] || 'grey.700',
                borderRadius: '5px',
                p: '4px 8px',
                '& > span': {
                    px: 0,
                    fontSize: '12px',
                },
                ...sx,
            }}
        />
    )
}
