import { MenuItem, Select as MuiSelect } from '@mui/material'
import useColor from 'hooks/useColor'

const MAX_LEN = 25

interface IProps {
    value: any
    onChange: any
    options: {
        list: any[]
        valueProp: any
        keyProp: any
        labelProp: any
    }
    labelMaxLen?: number
    displayEmpty?: boolean
    renderValue?: any
    MenuProps?: any
    placeholder?: string
    disabled?: boolean
    sx?: any
}

export default function Select({
    value,
    onChange,
    options: { list, valueProp, keyProp, labelProp },
    labelMaxLen = MAX_LEN,
    displayEmpty,
    renderValue,
    MenuProps = {},
    placeholder,
    disabled,
    sx = {},
}: IProps) {
    const color = useColor()
    return (
        <MuiSelect
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            displayEmpty={displayEmpty}
            renderValue={renderValue}
            fullWidth
            MenuProps={MenuProps}
            disabled={disabled}
            sx={{
                height: 44,
                '& > div.MuiInputBase-input': {
                    color: color.grey700,
                },
                '& > fieldset': {
                    border: '1px solid',
                    borderColor: color.grey300,
                    borderRadius: '5px',
                },
                '&.MuiOutlinedInput-root > .MuiInputBase-input': {
                    bgcolor: color.grey100,
                    borderRadius: '5px',
                    py: '11px',
                },
                '&.MuiOutlinedInput-root.Mui-focused > .MuiInputBase-input': {
                    bgcolor: color.paper,
                    borderRadius: '5px',
                    py: '11px',
                },
                '&.MuiOutlinedInput-root.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline ':
                    {
                        border: '1px solid',
                        borderColor: color.grey900,
                    },
                ...sx,
            }}
        >
            {list.map((item, idx, arr) => {
                const label = labelProp(item)
                return (
                    <MenuItem
                        key={keyProp(item)}
                        value={valueProp(item)}
                        sx={{
                            height: 40,
                            // bgcolor: 'grey.100',
                            borderRadius: '5px',
                            '&:hover': {
                                color: 'primary.main',
                            },
                            m: '10px',
                            // 목록을 감싸는 ul 에 paddingTop, paddingBottom 이 8px 로 사전 정의 되어 있어서
                            // 아래와 처음과 끝 요소의 margin 을 이용해 긴격 조정함. 22/1/20 Keating
                            ...(idx === 0 && {
                                mt: '2px',
                            }),
                            ...(idx === arr.length - 1 && {
                                mb: '2px',
                            }),
                        }}
                    >
                        {label.length > labelMaxLen
                            ? label.slice(0, labelMaxLen) + '..'
                            : label}
                    </MenuItem>
                )
            })}
        </MuiSelect>
    )
}
