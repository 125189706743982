import colors from 'assets/scss/_themes-vars.module.scss'
import {
    BizCategory,
    BizMgrRole,
    BizMgrStatus,
    BudgetType,
    ChangeType,
    FilterDimentionOperator,
    FilterMetricOperator,
    PrismStatus,
    RangeType,
} from 'model'
import { Colors, Theme } from 'types/index'
import { WeatherType } from 'model/weather'

export const TARGET_URL = 'target_url'
export const HEADER_ACCESS_TOKEN_NAME = 'access-token'
export const ACCESS_TOKEN_NAME = 'access_token'
export const REFRESH_TOKEN_NAME = 'refresh_token'
export const X_REFERER = 'aurora.lever.me'
export const TOKEN_REFRESH_ERROR = 'token refresh error'
export const COOKIE_DOMAIN = '.lever.me'
export const USER_ID_NAME = 'user_id'
export const AUTO_LOGIN_NAME = 'auto_login'
export const Z_INDEX = {
    confirm: 9999,
    loading: 10000,
}
export const IN_SCROLL = { overflow: 'auto', height: 'calc(100vh - 129px)' }

export const THEME: Theme = {
    borderRadius: '5px',
    borderRadius2: '10px',
    color: { ...(colors as Colors) },
}

export const NO_LEVER_USER = 'Not found user in BizLever'

export const CATEGORY_LABEL = {
    [BizCategory.Fashion]: '패션',
    [BizCategory.CosmeticsAndBeauty]: '화장품/미용',
    [BizCategory.HealthAndFood]: '건강/식품',
    [BizCategory.LifeAndGrocery]: '생활/잡화',
    [BizCategory.Service]: '서비스',
    [BizCategory.Finance]: '금융/보험',
    [BizCategory.Other]: '기타',
}

export const ROLE_LABEL = {
    [BizMgrRole.Master]: '마스터',
    [BizMgrRole.Member]: '멤버',
    [BizMgrRole.Viewer]: '뷰어',
}

export const STATUS_LABEL = {
    [BizMgrStatus.Requested]: '요청 대기',
    [BizMgrStatus.Invited]: '초대 대기',
    [BizMgrStatus.RejectedReq]: '요청 거부됨',
    [BizMgrStatus.RejectedInvite]: '초대 거부됨',
    [BizMgrStatus.CancelInvite]: '초대 취소됨',
    [BizMgrStatus.CancelReq]: '요청 취소됨',
}

export const USE_MADUP_ACCOUNT = 'use_madup_account'

export const RANGE_TYPE_LABEL = {
    [RangeType.CurrentMonth]: '당월',
    [RangeType.Last7Days]: '최근 7일',
    [RangeType.Last30Days]: '최근 30일',
}

export const FILE_FORMAT_LABEL = {
    daily: '일자별로 구분',
    total: '전체 합산',
}

export const CRON_EXP_DEFAULT_VALUE = '0 0 * * *'

export const DAY_NAMES = ['월', '화', '수', '목', '금', '토', '일']

export const REPEAT_OPTIONS = [
    {
        id: 'daily',
        name: '매일 업로드',
    },
    {
        id: 'weekly',
        name: '매주 지정된 요일에 업로드',
    },
    {
        id: 'monthly',
        name: '매월 지정된 날짜에 업로드',
    },
]

export const REPEAT_LABEL = {
    daily: '매일',
    weekly: '매주',
    monthly: '매월',
}

export const NUMBER_CONDITIONS = [
    { value: FilterMetricOperator.Equal, label: '같음' },
    { value: FilterMetricOperator.NotEqual, label: '같지 않음' },
    { value: FilterMetricOperator.GreaterThan, label: '보다 큼' },
    { value: FilterMetricOperator.GreaterEqualThan, label: '크거나 같음' },
    { value: FilterMetricOperator.LessThan, label: '보다 작음' },
    { value: FilterMetricOperator.LessEqualThan, label: '작거나 같음' },
]

export const STRING_CONDITIONS = [
    { value: FilterDimentionOperator.Equal, label: '같음' },
    { value: FilterDimentionOperator.NotEqual, label: '같지 않음' },
    { value: FilterDimentionOperator.In, label: '포함' },
    { value: FilterDimentionOperator.NotIn, label: '포함하지 않음' },
    { value: FilterDimentionOperator.StartsWith, label: '시작 문자' },
    { value: FilterDimentionOperator.NotStartsWith, label: '제외할 시작 문자' },
    { value: FilterDimentionOperator.EndsWith, label: '끝 문자' },
    { value: FilterDimentionOperator.NotEndsWith, label: '제외할 끝 문자' },
]

export const API_TOKEN_V1 = 'api_token_v1'

export const COLLECT_STATUS = {
    [PrismStatus.Enable]: { label: '정상수집', color: 'primary.main' },
    [PrismStatus.Disable]: { label: '수집중단', color: 'grey.700' },
    [PrismStatus.Wait]: { label: '정상수집', color: 'primary.main' },
    [PrismStatus.Run]: { label: '정상수집', color: 'primary.main' },
    [PrismStatus.Fail]: { label: '수집오류', color: 'error.main' },
    [PrismStatus.Error]: { label: '수집오류', color: 'error.main' },
    [PrismStatus.TimeOut]: { label: '수집오류', color: 'error.main' },
    [PrismStatus.Expired]: { label: '수집중단', color: 'grey.700' },
}

export const REFRESH_STATUS_LIST = [
    {
        color: 'grey.900',
        label: '데이터 없음',
    },
    {
        color: '#FF6B00',
        label: '168시간 이상',
    },
    {
        color: '#ffcb13',
        label: '168시간 이내',
    },
    {
        color: '#05dc41',
        label: '24시간 이내',
    },
    {
        color: 'primary.main',
        label: '2시간 이내',
    },
]

export const TIME_SECOND = 1000 // for  1s
export const TIME_MINUTE = TIME_SECOND * 60 // for 1 minute

export const DATE_FORMAT = 'YYYY.MM.DD'
export const TIME_FORMAT = 'HH:mm:ss'

export const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT

export const DATA_REFRESH_MAX_DAYS = 31

export const KRW_UNIT = '₩'
export const USD_UNIT = '$'

export const QUERY_KEY = {
    bizmoneyList: 'bizmoney-list',
    bizmoneyChart: 'bizmoney-chart',
    campaigns: 'campaigns',
    businessCampaigns: 'business-campaigns',
    adGroups: 'ad-groups',
    ads: 'ads',
    rules: 'rules',
    adAccountCampaigns: 'ad-account-campaigns',
    ruleLogs: 'rule-logs',
    kantRules: 'kant-rules',
    kantRule: 'kant-rule',
    kantRuleLogs: 'kant-rule-logs',
    adAccount: 'ad-account',
    adAccounts: 'ad-accounts',
    allBusinesses: 'all-businesses',
    androidApps: 'android-apps',
    business: 'business',
    businesses: 'businesses',
    businessCollect: 'business-collect',
    businessCollects: 'business-collects',
    businessDataExtract: 'business-data-extract',
    businessDataExtractLogs: 'business-data-extract-logs',
    businessDataExtracts: 'business-data-extracts',
    businessManagers: 'business-managers',
    channels: 'channels',
    columnAction: 'column-action',
    iosApps: 'ios-apps',
    managers: 'managers',
    reportTypeColumns: 'report-type-columns',
    reportTypes: 'report-types',
    users: 'users',
    webInfo: 'web-info',
    editableMessages: 'editable-messages',
}

export const SMALL_PADDING = {
    headerSX: { padding: '40px 60px 0px 60px' },
    contentSX: { '&.MuiCardContent-root': { paddingTop: '10px' } },
}

export const CHANGE_TYPE_LABEL = {
    [ChangeType.Status]: '상태',
    [ChangeType.Budget]: '예산',
    [ChangeType.BidAmount]: '입찰가',
}

export const BUDGET_TYPE_LABEL = {
    [BudgetType.AdgroupBudget]: '광고그룹 예산',
    [BudgetType.CampaignBudget]: '캠페인 예산',
    [BudgetType.TotalBudget]: '기간 예산',
}

export const INFINITE_LOADING_LIMIT = 1000
export const FIRST_LOADING_LIMIT = 100
export const ARGO_WARNING_TOOLTIP =
    '페이스북 관리자 > API 연동 페이지에서 아르고를 연동해 주세요.'

export const SERVICE_NANE = 'aurora'

export const TEMP = 'Temp'
export const MAX_TEMP = 'max' + TEMP
export const MIN_TEMP = 'min' + TEMP

export const WEATHER_TYPE_LABEL = {
    [WeatherType.Rain]: '☔︎ 비',
    [MAX_TEMP]: '☀️ 일 최고기온',
    [MIN_TEMP]: '🧊 일 최저기온',
    [WeatherType.Humidity]: '💦 습도',
    [WeatherType.Discomfort]: '🥵 불쾌지수',
}

export const TABLE_ID = {
    campaignTable: 'campaignTable',
    adGroupTable: 'adGroupTable',
    adTable: 'adTable',
    manageRuleTable: 'manageRuleTable',
    settingHistoryTable: 'settingHistoryTable',
}
