/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ResultStatus {
    Ready = 'ready',
    Run = 'run',
    Done = 'done',
    NoChange = 'no_change',
    Error = 'error',
    Timeout = 'timeout',
    Retire = 'retire',
}
