/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * STANDBY = auto()    # 실행 대기 상태   RUNNING = auto()    # 동작 상태   DONE = auto()       # 룰 완료 상태 (반복 수행없이 완료된 상태)   EXPIRED = auto()    # 룰 만료 상태 (end_time으로 인한 만료)   DELETED = auto()    # 룰 삭제된 상태   PAUSED = auto()     # 룰 중지 상태
 * @export
 * @enum {string}
 */

export enum RuleStatus {
    Standby = 'standby',
    Running = 'running',
    Done = 'done',
    Expired = 'expired',
    Deleted = 'deleted',
    Paused = 'paused',
}
