import { createElement, lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import PageNotFound from 'views/pages/PageNotFound'

const AuthLogin3 = Loadable(
    lazy(() => import('views/pages/authentication/authentication3/Login3')),
)
const AuthLogin = Loadable(
    lazy(() => import('views/pages/authentication/authentication3/Login')),
)
const AuthRegister3 = Loadable(
    lazy(() => import('views/pages/authentication/authentication3/Register3')),
)

const MinimalRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/login/login3',
            element: <AuthLogin3 />,
        },
        {
            path: '/login',
            element: <AuthLogin />,
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />,
        },
        {
            path: '/404',
            element: <PageNotFound />,
        },
        {
            path: '/basic/manage/ad-setting/max',
            element: lazyElement(() => import('views/basic/manage/ad-setting')),
        },
        {
            path: '/basic/money/max',
            element: lazyElement(() => import('views/basic/money')),
        },
        {
            path: '/basic/manage/setting-history/max',
            element: lazyElement(() =>
                import('views/basic/manage/setting-history'),
            ),
        },
        {
            path: '/basic/manage/manage-rule/max',
            element: lazyElement(() =>
                import('views/basic/manage/manage-rule'),
            ),
        },
        {
            path: '/basic/extract/refresh/max',
            element: lazyElement(() => import('views/basic/extract/refresh')),
        },
        {
            path: '/basic/channels/max',
            element: lazyElement(() => import('views/basic/channels')),
        },
        {
            path: '/basic/add-media/max',
            element: lazyElement(() =>
                import('views/basic/channels/add-account'),
            ),
        },
        {
            path: '/basic/business-setting/max',
            element: lazyElement(() =>
                import('views/basic/settings/business-setting'),
            ),
        },
        {
            path: '/basic/edit-business/max',
            element: lazyElement(() =>
                import('views/basic/settings/edit-business'),
            ),
        },
        {
            path: '/basic/manage-member/max',
            element: lazyElement(() =>
                import('views/basic/settings/manage-member'),
            ),
        },
    ],
}

export default MinimalRoutes

function lazyElement(factory) {
    return createElement(Loadable(lazy(factory)))
}
