import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import {
    Box,
    Drawer,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import { IconSettings } from '@tabler/icons'
import PerfectScrollbar from 'react-perfect-scrollbar'
import SubCard from 'ui-component/cards/SubCard'
import AnimateButton from 'ui-component/extended/AnimateButton'
import { SET_BORDER_RADIUS, SET_FONT_FAMILY } from 'store/actions'
import { gridSpacing } from 'store/constant'
import UnstyledSwitch from 'ui-component/extended/UnstyledSwitch'
import useDarkMode from 'zustands/useDarkMode'
import useColor from 'hooks/useColor'
import { evolve, not } from 'utils/lib'
import {
    AcUnit,
    Nightlight,
    TextFields,
    FilterVintage,
} from '@mui/icons-material'
import ParkIcon from '@mui/icons-material/Park'
import { isDec, isJan, isMar, isApr } from 'biz/index'

function valueText(value) {
    return `${value}px`
}

export default function Customization() {
    const theme = useTheme()
    const color = useColor()
    const dispatch = useDispatch()
    const customization = useSelector(state => state.customization)
    const { darkMode, snowy, christmas, sakura, set } = useDarkMode()

    // drawer on/off
    const [open, setOpen] = useState(false)
    const handleToggle = () => {
        setOpen(!open)
    }

    // state - border radius
    const [borderRadius, setBorderRadius] = useState(customization.borderRadius)
    const handleBorderRadius = (event, newValue) => {
        setBorderRadius(newValue)
    }

    useEffect(() => {
        dispatch({ type: SET_BORDER_RADIUS, borderRadius })
    }, [dispatch, borderRadius])

    let initialFont
    switch (customization.fontFamily) {
        case `'Inter', sans-serif`:
            initialFont = 'Inter'
            break
        case `'Poppins', sans-serif`:
            initialFont = 'Poppins'
            break
        case `'Roboto', sans-serif`:
        default:
            initialFont = 'Roboto'
            break
    }

    // state - font family
    const [fontFamily, setFontFamily] = useState(initialFont)
    useEffect(() => {
        let newFont
        switch (fontFamily) {
            case 'Inter':
                newFont = `'Inter', sans-serif`
                break
            case 'Poppins':
                newFont = `'Poppins', sans-serif`
                break
            case 'Roboto':
            default:
                newFont = `'Roboto', sans-serif`
                break
        }
        dispatch({ type: SET_FONT_FAMILY, fontFamily: newFont })
    }, [dispatch, fontFamily])

    return (
        <>
            {/* toggle button */}
            <Tooltip title="Live Customize">
                <Fab
                    component="div"
                    onClick={handleToggle}
                    size="medium"
                    variant="circular"
                    color="secondary"
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '50%',
                        position: 'fixed',
                        right: 10,
                        zIndex: theme.zIndex.speedDial,
                    }}
                >
                    <AnimateButton type="rotate">
                        <IconButton color="inherit" size="large" disableRipple>
                            <IconSettings />
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 280,
                    },
                }}
            >
                <PerfectScrollbar component="div">
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            {/* border radius */}
                            <SubCard
                                title={
                                    <Stack
                                        direction="row"
                                        spacing="7px"
                                        alignItems="center"
                                    >
                                        <Nightlight />
                                        <Box>Dark mode</Box>
                                    </Stack>
                                }
                            >
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="h6">
                                            ON / OFF
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <UnstyledSwitch
                                            checked={darkMode}
                                            onChange={() =>
                                                set({ darkMode: !darkMode })
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>

                        {(isMar || isApr) && (
                            <Grid item xs={12}>
                                {/* border radius */}
                                <SubCard
                                    title={
                                        <Stack
                                            direction="row"
                                            spacing="7px"
                                            alignItems="center"
                                        >
                                            <FilterVintage />
                                            <Box>Falling sakura</Box>
                                        </Stack>
                                    }
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography variant="h6">
                                                ON / OFF
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <UnstyledSwitch
                                                checked={sakura}
                                                onChange={() => {
                                                    set(evolve({ sakura: not }))
                                                    if (sakura) {
                                                        window.location.reload()
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}

                        {(isDec || isJan) && (
                            <Grid item xs={12}>
                                {/* border radius */}
                                <SubCard
                                    title={
                                        <Stack
                                            direction="row"
                                            spacing="7px"
                                            alignItems="center"
                                        >
                                            <AcUnit /> <Box>Falling snow</Box>
                                        </Stack>
                                    }
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography variant="h6">
                                                ON / OFF
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <UnstyledSwitch
                                                checked={snowy}
                                                onChange={() => {
                                                    set(evolve({ snowy: not }))
                                                    if (snowy) {
                                                        window.location.reload()
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}

                        {isDec && (
                            <Grid item xs={12}>
                                {/* border radius */}
                                <SubCard
                                    title={
                                        <Stack
                                            direction="row"
                                            spacing="7px"
                                            alignItems="center"
                                        >
                                            <ParkIcon />{' '}
                                            <Box>Christmas decoration</Box>
                                        </Stack>
                                    }
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography variant="h6">
                                                ON / OFF
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <UnstyledSwitch
                                                checked={christmas}
                                                onChange={() => {
                                                    set(
                                                        evolve({
                                                            christmas: not,
                                                        }),
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            {/* font family */}
                            <SubCard
                                title={
                                    <Stack
                                        direction="row"
                                        spacing="7px"
                                        alignItems="center"
                                    >
                                        <TextFields />
                                        <Box> Font family</Box>
                                    </Stack>
                                }
                            >
                                <FormControl>
                                    <RadioGroup
                                        aria-label="font-family"
                                        value={fontFamily}
                                        onChange={e =>
                                            setFontFamily(e.target.value)
                                        }
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="Roboto"
                                            control={<Radio />}
                                            label="Roboto"
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 28,
                                                },
                                                '& .MuiFormControlLabel-label':
                                                    {
                                                        color: color.grey900,
                                                    },
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Poppins"
                                            control={<Radio />}
                                            label="Poppins"
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 28,
                                                },
                                                '& .MuiFormControlLabel-label':
                                                    {
                                                        color: color.grey900,
                                                    },
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Inter"
                                            control={<Radio />}
                                            label="Inter"
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 28,
                                                },
                                                '& .MuiFormControlLabel-label':
                                                    {
                                                        color: color.grey900,
                                                    },
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    /!* border radius *!/*/}
                        {/*    <SubCard title="Border Radius">*/}
                        {/*        <Grid*/}
                        {/*            item*/}
                        {/*            xs={12}*/}
                        {/*            container*/}
                        {/*            spacing={2}*/}
                        {/*            alignItems="center"*/}
                        {/*            sx={{ mt: 2.5 }}*/}
                        {/*        >*/}
                        {/*            <Grid item>*/}
                        {/*                <Typography variant="h6" color="secondary">*/}
                        {/*                    4px*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item xs>*/}
                        {/*                <Slider*/}
                        {/*                    size="small"*/}
                        {/*                    value={borderRadius}*/}
                        {/*                    onChange={handleBorderRadius}*/}
                        {/*                    getAriaValueText={valueText}*/}
                        {/*                    valueLabelDisplay="on"*/}
                        {/*                    aria-labelledby="discrete-slider-small-steps"*/}
                        {/*                    marks*/}
                        {/*                    step={2}*/}
                        {/*                    min={4}*/}
                        {/*                    max={24}*/}
                        {/*                    color="secondary"*/}
                        {/*                    sx={{*/}
                        {/*                        '& .MuiSlider-valueLabel': {*/}
                        {/*                            color: 'secondary.light',*/}
                        {/*                        },*/}
                        {/*                    }}*/}
                        {/*                />*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item>*/}
                        {/*                <Typography variant="h6" color="secondary">*/}
                        {/*                    24px*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*        </Grid>*/}
                        {/*    </SubCard>*/}
                        {/*</Grid>*/}
                    </Grid>
                </PerfectScrollbar>
            </Drawer>
        </>
    )
}
