import createLogger, { consoleTransport, simpleFormat } from 'if-logger'
import dayjs from 'dayjs'
import { parseSearchParams, prop } from 'utils/lib'

function customTransport(level, message, formatMessage) {
    /*
     * level: 'debug'
     * message: 'some text'
     * formatMessage: '[debug] some text'
     */
    //api.pushLog(level + ' : ' + message)
}

export default createLogger({
    level:
        prop('LOG_LEVEL', parseSearchParams(window.location.href)) ||
        process.env.REACT_APP_LOG_LEVEL,
    format:
        process.env.REACT_APP_LOG_SIMPLE_FORMAT === 'true'
            ? simpleFormat
            : format,
    tags: [
        () =>
            dayjs().format(
                process.env.REACT_APP_LOG_DATETIME_FORMAT ||
                    'YYYY-MM-DD HH:mm:ss',
            ),
    ],
    transports: [consoleTransport, customTransport],
})

function format(level, tags, message) {
    const tagstr = tags
        .map(tag => (typeof tag === 'function' ? tag() : tag))
        .join(' ')
    return `${level.toUpperCase()} ${tagstr} ${message}`
}
