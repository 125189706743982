import { useTheme } from '@mui/material'
import useDarkMode from 'zustands/useDarkMode'

export default function DocIcon() {
    const theme = useTheme()
    const { darkMode } = useDarkMode()
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                stroke={
                    darkMode ? theme.palette.grey[50] : theme.palette.grey[700]
                }
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M14.2856 2V6.57143C14.2856 6.87453 14.4061 7.16522 14.6204 7.37955C14.8347 7.59388 15.1254 7.71429 15.4285 7.71429H19.9999" />
                <path d="M17.7143 22.5714H6.28571C5.67951 22.5714 5.09812 22.3306 4.66947 21.902C4.24082 21.4733 4 20.8919 4 20.2857V4.28571C4 3.67951 4.24082 3.09812 4.66947 2.66947C5.09812 2.24082 5.67951 2 6.28571 2H14.2857L20 7.71429V20.2857C20 20.8919 19.7592 21.4733 19.3305 21.902C18.9019 22.3306 18.3205 22.5714 17.7143 22.5714Z" />
                <path d="M8.57153 18.0001V12.2858" />
                <path d="M12 18V16.8572" />
                <path d="M15.4285 18V14.5714" />
            </g>
        </svg>
    )
}
