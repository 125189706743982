import axios from 'axios'
import { matched, omitProto } from 'utils/lib'

export default async function request({ path, option, headers }) {
    const $logger = window.$logger.addTags('request')
    const url = path.startsWith('http')
        ? path
        : process.env.REACT_APP_API_SERVER_URL + path
    const input = matched(['get', 'delete'], option.method)
        ? option.params
        : option.data

    const result = await axios({
        url,
        ...option,
        headers: { ...headers, ...option.headers },
    })
    $logger.debug(
        `[${option.method}] ` + path,
        omitProto({
            input,
            output: result.data,
        }),
    )
    return result.data
}
