import create from 'zustand'
import { persist } from 'zustand/middleware'
import { DumbFunction } from '../types'

interface State {
    pairs: string[]
}

interface Store extends State {
    set: (param: State) => void
    clear: DumbFunction
}

export const BOOKMARK_STATE_NAME = 'bookmarks'

const useBookmarks = create<Store>(
    persist(
        set => ({
            pairs: [],
            set,
            clear: () => set({}, true),
        }),
        {
            name: BOOKMARK_STATE_NAME, // unique name
            getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
        },
    ),
)

export default useBookmarks
