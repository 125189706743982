import { QUERY_KEY } from 'constants/index'
import { faker } from '@faker-js/faker'
import {
    ChangeType,
    Region,
    RuleStatus,
    RuleType,
    Sign,
    TargetTime,
    TargetType,
    WeatherMetricType,
    WeatherType,
} from 'model/weather'
import { ChannelType } from 'model/channel-type'
import { prop, values } from 'utils/lib'
import { channels } from './static-data'

const channelList = channels
    .filter(item => item.type !== ChannelType.KakaoBrand)
    .map(prop('type'))

export const resolver = {
    [QUERY_KEY.channels]: (req, res, ctx) => {
        return res(ctx.json(channels))
    },
    [QUERY_KEY.kantRuleLogs]: (req, res, ctx) => {
        return res(
            ctx.json(
                Array.from({ length: 10 }, (_, idx) => {
                    const change_type = faker.helpers.arrayElement([
                        ChangeType.Status,
                        ChangeType.Budget,
                        ChangeType.BidAmount,
                    ])
                    const before =
                        change_type === ChangeType.Status
                            ? faker.helpers.arrayElement(['active', 'paused'])
                            : String(Number(faker.random.numeric(4)) * 1000)
                    const after =
                        change_type === ChangeType.Status
                            ? faker.helpers.arrayElement(['active', 'paused'])
                            : String(Number(faker.random.numeric(4)) * 1000)
                    return {
                        idx,
                        id: faker.random.numeric(4),
                        name: faker.word.verb(),
                        biz_id: faker.random.numeric(4),
                        biz_name: faker.company.name(),
                        type: faker.helpers.arrayElement(
                            Object.values(RuleType),
                        ),
                        run_at: faker.date.between(
                            '2023-04-01T00:00:00.000Z',
                            '2023-06-01T00:00:00.000Z',
                        ),
                        requested_by: faker.internet.email(),
                        result_code: faker.helpers.arrayElement([200, 500]),
                        target: {
                            media: faker.helpers.arrayElement(channelList),
                            account_id: faker.random.numeric(),
                            account_name: faker.word.adjective(),
                            target_id: faker.word.verb(),
                            target_name: faker.word.verb(),
                            target_type: faker.helpers.arrayElement([
                                'campaign',
                                'adgroup',
                                'ad',
                            ]),
                        },
                        condition: {
                            type: faker.helpers.arrayElement(
                                Object.values(WeatherType),
                            ),
                            reg: [
                                faker.helpers.arrayElement(
                                    Object.values(Region),
                                ),
                            ],
                            target_time: [
                                faker.helpers.arrayElement(
                                    Object.values(TargetTime),
                                ),
                            ],
                            conditions: [
                                {
                                    metricType: faker.helpers.arrayElement(
                                        Object.values(WeatherMetricType),
                                    ),
                                    sign: faker.helpers.arrayElement(
                                        Object.values(Sign),
                                    ),
                                    value: faker.random.numeric(2),
                                },
                            ],
                        },
                        result_msg: faker.word.noun(),
                        change: {
                            change_type,
                            after,
                            before,
                        },
                    }
                }),
            ),
        )
    },
    [QUERY_KEY.kantRules]: (req, res, ctx) => {
        return res(
            ctx.json(
                Array.from({ length: 30 }, () => {
                    const change_type = faker.helpers.arrayElement([
                        ChangeType.Status,
                        ChangeType.Budget,
                        ChangeType.BidAmount,
                    ])
                    const before =
                        change_type === ChangeType.Status
                            ? faker.helpers.arrayElement(['active', 'paused'])
                            : String(Number(faker.random.numeric(4)) * 1000)
                    const after =
                        change_type === ChangeType.Status
                            ? faker.helpers.arrayElement(['active', 'paused'])
                            : String(Number(faker.random.numeric(4)) * 1000)
                    return {
                        id: faker.datatype.number(),
                        group_id: faker.datatype.number(),
                        biz_id: faker.datatype.number(),
                        biz_name: faker.company.companyName(),
                        status: faker.helpers.arrayElement(values(RuleStatus)),
                        created_by: faker.internet.email(),
                        updated_by: faker.internet.email(),
                        updated_at: faker.datatype.datetime(),
                        latest_at: faker.date.between(
                            '2023-04-01T00:00:00.000Z',
                            '2023-05-15T00:00:00.000Z',
                        ),
                        schedule_time: faker.date.between(
                            '2023-05-15T00:00:00.000Z',
                            '2023-09-15T00:00:00.000Z',
                        ),
                        created_at: faker.datatype.datetime(),
                        change: {
                            change_type,
                            after,
                            before,
                        },
                        target: {
                            media: faker.helpers.arrayElement(channelList),
                            account_id: faker.random.numeric(),
                            account_name: faker.word.adjective(),
                            target_id: faker.word.verb(),
                            target_name: faker.word.verb(),
                            target_type: faker.helpers.arrayElement(
                                values(TargetType),
                            ),
                        },
                        condition: {
                            type: faker.word.verb(),
                        },
                    }
                }),
            ),
        )
    },
}
