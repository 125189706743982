import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'

const MinimalLayout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <>
            {location.pathname !== '/login' && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        height: 0,
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    <CloseFullscreenIcon
                        onClick={() =>
                            navigate(
                                location.pathname.slice(0, -4) +
                                    location.search,
                            )
                        }
                        sx={{
                            cursor: 'pointer',
                            margin: '5px',
                            '&:hover': { color: 'primary.main' },
                        }}
                    />
                </Box>
            )}
            <Outlet />
        </>
    )
}

export default MinimalLayout
