import { useTheme } from '@mui/system'
import { Avatar, Box, ButtonBase } from '@mui/material'
import LogoSection from '../LogoSection'
import SearchSection from './SearchSection'
import ProfileSection from './ProfileSection'
import NotificationSection from './NotificationSection'
import { IconMenu2 } from '@tabler/icons'
import Button from 'ui-component/Button'
import { useNavigate } from 'react-router'
import Tooltip from 'ui-component/Tooltip'
import useDarkMode from 'zustands/useDarkMode'
import { isApr, isDec, isMar } from 'biz/index'
import Bgm from './Bgm'

export default function Header({ handleLeftDrawerToggle }) {
    const theme = useTheme() as any
    const navigate = useNavigate()
    const { christmas } = useDarkMode()

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box
                    component="span"
                    sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
                >
                    <LogoSection />
                </Box>

                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Tooltip title="단축키: `[`" placement="left">
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.grey[200],
                                color: theme.palette.grey[700],
                                '&:hover': {
                                    background: theme.palette.grey[300],
                                    color: theme.palette.grey[900],
                                },
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </Tooltip>
                </ButtonBase>
            </Box>
            <Button
                variant="outlined"
                color="primary"
                sx={{ ml: '30px' }}
                onClick={() => navigate('/basic/businesses')}
            >
                광고주 목록
            </Button>
            {isDec && christmas && (
                <Box sx={{ fontSize: 30, ml: '10px', whiteSpace: 'nowrap' }}>
                    🎄☃️🎄
                </Box>
            )}
            {process.env.REACT_APP_GNB_HIDDEN_SEARCH_SECTION !== 'true' && (
                <SearchSection />
            )}
            <Box sx={{ flexGrow: 1 }} />
            {(isApr || isMar) && <Bgm />}
            <Box sx={{ flexGrow: 1 }} />

            {process.env.REACT_APP_GNB_HIDDEN_NOTI_SECTION !== 'true' && (
                <NotificationSection />
            )}
            <ProfileSection />
        </>
    )
}
