import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import AuthWrapper1 from './authentication/AuthWrapper1'
import AuthCardWrapper from './authentication/AuthCardWrapper'
import Logo from 'ui-component/svgs/Logo'
import AuthFooter from 'ui-component/cards/AuthFooter'
import { Box } from '@mui/system'

export default function PageNotFound() {
    const theme = useTheme()
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <AuthWrapper1>
            <Stack sx={{ minHeight: '100vh', justifyContent: 'space-between' }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ flex: 1 }}
                >
                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                        <AuthCardWrapper>
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item sx={{ mb: 3 }}>
                                    <Link to="/">
                                        <Logo />
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={1}
                                    >
                                        <Typography
                                            color={theme.palette.secondary.main}
                                            gutterBottom
                                            variant={matchDownSM ? 'h3' : 'h2'}
                                        >
                                            404 Page not found
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            fontSize="16px"
                                            textAlign={
                                                matchDownSM
                                                    ? 'center'
                                                    : 'inherit'
                                            }
                                        >
                                            <b>
                                                <i>
                                                    {window.decodeURIComponent(
                                                        window.location
                                                            .pathname,
                                                    )}
                                                </i>
                                            </b>
                                            is invalid pathname
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </AuthCardWrapper>
                    </Grid>
                </Grid>
                <Box sx={{ m: '8px 24px 24px 24px' }}>
                    <AuthFooter />
                </Box>
            </Stack>
        </AuthWrapper1>
    )
}
