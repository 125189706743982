import { createStore } from 'redux'
import reducer from './reducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
    },
    reducer,
)
const store = createStore(persistedReducer)
const persister = persistStore(store)

export { store, persister }
