import { Box, Divider, Typography } from '@mui/material'
import NavGroup from './NavGroup'
import menuItem from 'menu-items'
import { strMatched } from '@madup-inc/utils'
import { trim } from 'utils/lib'

const hiddenMenuIds =
    process.env.REACT_APP_LNB_HIDDEN_MENU_IDS?.split(',').map(trim) || []

const MenuList = () => {
    const navItems = menuItem.items
        .filter(item => !strMatched(hiddenMenuIds, item.id))

        .map(item => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />
                case 'element':
                    return (
                        <Box key={item.id}>
                            {item.element}
                            {/* group divider */}
                            <Divider sx={{ my: '20px' }} />
                        </Box>
                    )
                default:
                    return (
                        <Typography
                            key={item.id}
                            variant="h6"
                            color="error"
                            align="center"
                        >
                            Menu Items Error
                        </Typography>
                    )
            }
        })

    return <>{navItems}</>
}

export default MenuList
