import { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import SubLayout from 'layout/SubLayout'
const Businesses = Loadable(lazy(() => import('views/basic/businesses')))

const SubRoutes = {
    path: '/',
    element: <SubLayout />,
    children: [
        {
            path: '/basic/businesses',
            element: <Businesses />,
        },
    ],
}

export default SubRoutes
