import { useEffect } from 'react'
import { loadJs } from '@madup-inc/utils'
import useDarkMode from 'zustands/useDarkMode'
import { isDec, isJan } from 'biz/index'

export default function useSnowy() {
    const { snowy } = useDarkMode()
    useEffect(() => {
        if ((isDec || isJan) && snowy) {
            loadJs('/snow3d.js')
        }
    }, [snowy])
}
