const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/basic/channels',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 5,
}

export default config
