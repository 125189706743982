// material-ui
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { Z_INDEX } from 'constants/index'

// styles
const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: Z_INDEX.loading,
    width: '100%',
})

// ==============================|| LOADER ||============================== //
export default function Loader() {
    return (
        <LoaderWrapper>
            <LinearProgress color="primary" />
        </LoaderWrapper>
    )
}
