import { Box, Stack, Typography } from '@mui/material'
import Button from './Button'
import { THEME, Z_INDEX } from 'constants/index'
import { ReactNode } from 'react'
import useColor from 'hooks/useColor'

interface Option {
    okSx?: any
    okColor?: string
}

interface IProps {
    title: string
    desc: ReactNode
    onCancel: any
    onOk: any
    close: any
    cancelButtonHidden?: boolean
    okLabel?: string
    cancelLabel?: string
    options?: Option
    closeOnOuterClick?: boolean
}

export default function Confirm({
    title,
    desc,
    onCancel,
    onOk,
    close,
    cancelButtonHidden,
    okLabel,
    cancelLabel,
    options,
    closeOnOuterClick = true,
}: IProps) {
    const color = useColor()
    return (
        <Box
            sx={{
                position: 'fixed',
                opacity: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 1,
                height: 1,
                top: 0,
                left: 0,
                zIndex: Z_INDEX.confirm,
            }}
            onClick={() => {
                if (closeOnOuterClick) {
                    close()
                }
            }}
        >
            <Box
                sx={{
                    width: 460,
                    boxShadow: color.shadow,
                    p: '80px 40px 30px',
                    bgcolor: color.paper,
                    borderRadius: THEME.borderRadius2,
                }}
                onClick={e => e.stopPropagation()}
            >
                <Stack spacing="40px">
                    <Stack spacing="20px" sx={{ textAlign: 'center' }}>
                        <Box>
                            {typeof title === 'string' ? (
                                <Typography variant="h2">{title}</Typography>
                            ) : (
                                title
                            )}
                        </Box>
                        <Box sx={{ maxHeight: 500, overflow: 'auto' }}>
                            {desc &&
                                (typeof desc === 'string' ? (
                                    <Typography
                                        variant="body1"
                                        sx={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {desc}
                                    </Typography>
                                ) : (
                                    desc
                                ))}
                        </Box>
                    </Stack>
                    <Stack direction="row" spacing="20px" justifyContent="end">
                        {!cancelButtonHidden && (
                            <Button
                                size="large"
                                onClick={() => {
                                    if (onCancel) {
                                        onCancel(close)
                                    } else {
                                        close()
                                    }
                                }}
                            >
                                {cancelLabel}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                                if (onOk) {
                                    onOk(close)
                                } else {
                                    close()
                                }
                            }}
                            sx={options?.okSx}
                            color={options?.okColor}
                        >
                            {okLabel}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}
