import useDarkMode from 'zustands/useDarkMode'
import colors from 'assets/scss/_themes-vars.module.scss'

interface ThemeColor {
    main: string
    light: string
    primary800: string
    primary200: string
    dark: string
    paper: string
    grey50: string
    grey100: string
    grey200: string
    grey300: string
    grey500: string
    grey600: string
    grey700: string
    grey900: string
    shadow: string
    error: string
}

export default function useColor(): ThemeColor {
    const { darkMode: dark } = useDarkMode()
    return {
        main: dark ? colors.primaryLight : colors.primaryMain,
        light: dark ? colors.primaryDark : colors.primaryLight,
        primary800: dark ? colors.primary200 : colors.primary800,
        primary200: dark ? colors.primary800 : colors.primary200,
        dark: dark ? colors.primaryLight : colors.primaryDark,
        paper: dark ? colors.grey900 : colors.paper,
        grey50: dark ? colors.grey700 : colors.grey50,
        grey100: dark ? colors.grey600 : colors.grey100,
        grey200: dark ? colors.grey500 : colors.grey200,
        grey300: colors.grey300,
        grey500: dark ? colors.grey200 : colors.grey500,
        grey600: dark ? colors.grey100 : colors.grey600,
        grey700: dark ? colors.grey50 : colors.grey700,
        grey900: dark ? colors.paper : colors.grey900,
        shadow: dark ? '0px 4px 50px rgba(256, 256, 256, 0.2)' : colors.shadow,
        error: colors.errorMain,
    }
}
