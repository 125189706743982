import { Box, Stack } from '@mui/material'

export default function Bgm() {
    return (
        <Stack direction="row" spacing="5px" alignItems="center">
            <Box
                sx={{
                    fontStyle: 'italic',
                    color: '#777777',
                    fontWeight: 'bold',
                }}
            >
                🎵 벚꽃엔딩 - 버스커버스커
            </Box>
            <audio autoPlay={false} controls loop style={{ height: 30 }}>
                {/* 크롬에서 autoPlay 가 잘 동작하지 않음. 23.03.24 Keating */}
                <source
                    src="https://aurora-prd-public.s3.ap-northeast-2.amazonaws.com/bgm/sakura.mp3"
                    type="audio/mp3"
                />
            </audio>
        </Stack>
    )
}
