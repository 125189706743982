import dashboard from './dashboard'
import pages from './pages'
import designGuide from './design-guide'
import other from './other'
import menu from './menu'
import business from './business'

const menuItems = {
    items: [business, dashboard, menu, pages, designGuide, other],
}

export default menuItems
