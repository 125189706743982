import { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { LoadingProvider } from 'react-hot-loading'
import Routes from 'routes'
import themes from 'themes'
import NavigationScroll from 'layout/NavigationScroll'
import Loading from './ui-component/Loading'
import { prop } from 'ramda'
import { QueryClientProvider } from 'react-query'
import ErrorBoundary from './ErrorBoundary'
import useSentry from 'hooks/useSentry'
import { Toaster } from 'react-hot-toast'
import { Confirmer } from './Confirmer'
import Confirm from './ui-component/Confirm'
import ErrorScreen from './ui-component/ErrorScreen'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import queryClient from 'utils/query-client'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import useDarkMode from 'zustands/useDarkMode'
import useColor from 'hooks/useColor'
import useSnowy from 'hooks/useSnowy'
import useSakura from 'hooks/useSakura'

export default function App() {
    const customization = useSelector(prop('customization'))
    const { darkMode: dark } = useDarkMode()
    const color = useColor()

    useSentry()
    useSnowy()
    useSakura()

    return (
        <StyledEngineProvider injectFirst>
            <ErrorBoundary fallback={ErrorScreen}>
                <ThemeProvider theme={themes(customization, dark, color)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Suspense fallback={<Loading />}>
                            <QueryClientProvider client={queryClient}>
                                <DndProvider backend={HTML5Backend}>
                                    <Routes />
                                </DndProvider>
                            </QueryClientProvider>
                        </Suspense>
                    </NavigationScroll>
                    <Confirmer ConfirmComponent={Confirm} />
                    <LoadingProvider loading={<Loading />} />
                </ThemeProvider>
            </ErrorBoundary>
            <Toaster
                toastOptions={{
                    className: 'toasterWrapper',
                }}
            />
        </StyledEngineProvider>
    )
}
