/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Manager : REQUESTED -> Master (IN or REJECTED_REQ), Manager(CANCEL_REQ), SYSTEM (EXPIRED_REQ) Master : INVITED -> Manager(IN, REJECTED_INVITE) or Master(CANCEL_INVITE), SYSTEM (EXPIRED_INVITE)
 * @export
 * @enum {string}
 */

export enum BizMgrStatus {
    In = 'in',
    Out = 'out',
    Requested = 'requested',
    RejectedReq = 'rejected_req',
    CancelReq = 'cancel_req',
    ExpiredReq = 'expired_req',
    Invited = 'invited',
    RejectedInvite = 'rejected_invite',
    CancelInvite = 'cancel_invite',
    ExpiredInvite = 'expired_invite',
}
