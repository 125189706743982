import req from 'api/req'
import { SERVICE_NANE, X_REFERER } from 'constants/index'
import {
    Action,
    Ad,
    AdAccountRecipe,
    AdAccountStatus,
    AdAccountStatusChange,
    AdGroup,
    AdGroups,
    Ads,
    BizMgrRole,
    BizMgrStatus,
    Business,
    BusinessRecipe,
    Campaign,
    Campaigns,
    ChangeItem,
    ChannelType,
    CollectRefreshDate,
    Extract,
    ExtractStatus,
    PulledResult,
    RulePostParam,
    RuleUpdateParam,
    UpdatedCampaign,
} from 'model'
import { evolve, identity, replace } from 'utils/lib'
import { RuleGroupRequest, RuleRequest } from 'model/weather'

export const getUserInfo = async (accessToken: string) =>
    await req.get(
        'https://www.googleapis.com/oauth2/v1/userinfo',
        { alt: 'json' },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    )

// 로그인
export const Auth = {
    loginGoogle: data =>
        req.post(
            process.env.REACT_APP_AUTH_API_URL + '/auth/login/google',
            data,
            {
                headers: {
                    'x-referer': X_REFERER,
                    'response-uri': window.location.origin + '/login',
                },
            },
        ),
    me: () => req.get(process.env.REACT_APP_LEVER_API_URL + '/users/me'),
    login: () => req.get('/auth/login'),
    signUp: ({ code, id_token, temp_token }) =>
        req.post('/auth/signup', { code, id_token, temp_token }),
}

export const BusinessApi = {
    getBusinesses: (): Promise<Business[]> => req.get('/businesses'),
    getAllBusinesses: (): Promise<Business[]> =>
        req.get('/businesses', { only_in: false }),
    getBusiness: ({ bizId }): Promise<Business> =>
        req.get('/businesses/' + bizId),
    addBusiness: (data: BusinessRecipe) => req.post('/businesses', data),
    updateBusiness: (data: Business) => req.put('/businesses/' + data.id, data),
    uploadImage: ({ bizId, file }) => {
        const formData = new FormData()
        formData.append('image', file)
        return req.post('/businesses/' + bizId + '/image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    getWebInfo: (url, option = {}) =>
        req.get('/platforms/web', { url }, option),
}

interface GetManagers {
    keyword: string
    next?: number
    limit?: number
}

export const ManagerApi = {
    getManagers: (param: GetManagers) =>
        req.get('/managers', {
            keyword: param.keyword,
            next: param.next,
            limit: param.limit,
        }),
    getManager: id => req.get('/managers/' + id),
    reloadManager: id => req.put('/managers/' + id, undefined),
}

export const BusinessManagerApi = {
    getManagers: ({ bizId }) => req.get('/businesses/' + bizId + '/managers'),
    requestInvite: ({ bizId, mgrId, role }) =>
        req.post('/businesses/' + bizId + '/managers/' + mgrId + '/invite', {
            role,
        }),
    responseInvite: ({ bizId, mgrId, before, after }) =>
        req.put('/businesses/' + bizId + '/managers/' + mgrId + '/invite', {
            before,
            after,
        }),
    requestJoin: ({ bizId, mgrId }) =>
        req.post('/businesses/' + bizId + '/managers/' + mgrId + '/join', {
            biz_id: bizId,
            mgr_id: mgrId,
            status: BizMgrStatus.Requested,
            role: BizMgrRole.Viewer,
        }),
    responseJoin: ({ bizId, mgrId, before, after }) =>
        req.put('/businesses/' + bizId + '/managers/' + mgrId + '/join', {
            before,
            after,
        }),
    updateRole: (param: {
        bizId: number
        mgrId: number
        before: BizMgrRole
        after: BizMgrRole
    }) =>
        req.put(`/businesses/${param.bizId}/managers/${param.mgrId}/role`, {
            before: param.before,
            after: param.after,
        }),
    changeMaster: (params: { bizId: number; mgrId: number }) =>
        req.put(
            `/businesses/${params.bizId}/managers/${params.mgrId}/master`,
            undefined,
        ),
    updateStatus: ({ bizId, member, status }) =>
        req.put(`/businesses/${bizId}/managers/${member.id}/status`, {
            before: member.status,
            after: status,
        }),
    deleteManager: ({ bizId, mgrId }) =>
        req.delete(`/businesses/${bizId}/managers/${mgrId}`),
}

export const BusinessCollectApi = {
    getCollects: ({ bizId }) => req.get('/businesses/' + bizId + '/collects'),
    getCollect: ({ bizId, collectId, endDate }) =>
        req.get('/businesses/' + bizId + '/collects/' + collectId, {
            end_date: endDate,
        }),
    postCollect: ({ bizId, collectId }, dates: CollectRefreshDate) =>
        req.post(
            '/businesses/' + bizId + '/collects/' + collectId + '/refresh',
            dates,
        ),
}

export const BusinessDataApi = {
    getExtractList: ({ bizId }) =>
        req.get('/businesses/' + bizId + '/extracts', {
            limit: 300,
            offset: 0,
        }),
    getExtractLogs: ({ bizId }) =>
        req.get('/businesses/' + bizId + '/extracts/logs'),
    getExtract: ({ bizId, extractId }) =>
        req.get('/businesses/' + bizId + '/extracts/' + extractId),
    postExtract: ({ bizId, adAccounts, channel, name }) =>
        req.post('/businesses/' + bizId + '/extracts', {
            biz_id: bizId,
            ad_accounts: adAccounts,
            channel,
            name,
        }),
    putExtractUnpublished: (
        param: { bizId: number; extractId: number; publish?: boolean },
        data: Extract,
    ) =>
        req.put(
            `/businesses/${param.bizId}/extracts/${param.extractId}/add?publish=` +
                (param.publish ?? 'false'),
            data,
        ),
    putExtractPublished: (
        param: { bizId: number; extractId: number },
        data: Extract,
    ) =>
        req.put(`/businesses/${param.bizId}/extracts/${param.extractId}`, data),
    putExtractStatus: (
        param: { bizId: number; extractId: number },
        data: { before: ExtractStatus; after: ExtractStatus },
    ) =>
        req.put(
            `/businesses/${param.bizId}/extracts/${param.extractId}/status`,
            data,
        ),
    deleteExtract: ({ bizId, extractId }) =>
        req.delete('/businesses/' + bizId + '/extracts/' + extractId),
    getActions: ({ bizId, adAccountId }): Promise<Action[]> =>
        req.get(`/businesses/${bizId}/adaccounts/${adAccountId}/actions`),
}

export const Channel = {
    getChannels: () => req.get('/channels'),
    getReportTypes: ({ channel }) =>
        req.get(`/channels/${channel}/report_types`),
    getReportTypeColumns: ({ channel, reportType }) =>
        req.get(`/channels/${channel}/report_types/${reportType}`),
    getFacebookTos: ({ bizId }) =>
        req.get(`/businesses/${bizId}/adops/media/facebook/tos`),
}

export const AdAccountApi = {
    getAdAccounts: ({ bizId, channel, withAccounts }) =>
        req.get(`/businesses/${bizId}/adaccounts`, {
            channel,
            with_accounts: withAccounts,
        }),
    getAdAccount: (bizId: number, adAccountId: number) =>
        req.get(`/businesses/${bizId}/adaccounts/${adAccountId}`),
    addAdAccount: (bizId: number, payload: Omit<AdAccountRecipe, 'status'>) =>
        req.post(`/businesses/${bizId}/adaccounts`, {
            channel: payload.channel,
            info: evolve(
                {
                    customer_id:
                        payload.channel === ChannelType.Google
                            ? replace(/-/g, '')
                            : identity,
                    login_customer_id:
                        payload.channel === ChannelType.Google
                            ? replace(/-/g, '')
                            : identity,
                },
                payload.info,
            ),
            config: payload.config,
            status: AdAccountStatus.Standby,
            name: payload.name,
        }),
    updateAdAccount: (
        bizId: number,
        adAccountId: number,
        payload: AdAccountRecipe,
    ) =>
        req.put(`/businesses/${bizId}/adaccounts/${adAccountId}`, {
            info: payload.info,
            config: payload.config,
            channel: payload.channel,
            status: payload.status,
            name: payload.name,
        }),
    deleteAdAccount: ({ bizId, adAccountId }) =>
        req.delete(`/businesses/${bizId}/adaccounts/${adAccountId}`),
    changeStatus: (
        bizId: number,
        adAccountId: number,
        payload: AdAccountStatusChange,
    ) =>
        req.put(`/businesses/${bizId}/adaccounts/${adAccountId}/status`, {
            before: payload.before,
            after: payload.after,
        }),
    uploadPem: ({ file }) => {
        const formData = new FormData()
        formData.append('account_file', file)
        return req.post('/upload/account_auth', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
}

export const AdOpsApi = {
    putMediaCampaign: ({ bizId, adAccountId, campaignId }, data: ChangeItem) =>
        req.put(
            `/businesses/${bizId}/adops/media/accounts/${adAccountId}/campaigns/${campaignId}`,
            data,
        ),
    putMediaAdGroup: ({ bizId, adAccountId, adGroupId }, data: ChangeItem) =>
        req.put(
            `/businesses/${bizId}/adops/media/accounts/${adAccountId}/adgroups/${adGroupId}`,
            data,
        ),
    putMediaAd: ({ bizId, adAccountId, adId }, data: ChangeItem) =>
        req.put(
            `/businesses/${bizId}/adops/media/accounts/${adAccountId}/ads/${adId}`,
            data,
        ),
    putCampaignSelected: (
        { bizId, adAccountId },
        updatedCampaigns: UpdatedCampaign[],
    ) =>
        req.put(
            `/businesses/${bizId}/adaccounts/${adAccountId}/campaigns`,
            updatedCampaigns,
        ),
    getEditableMessages: () => req.get(`/adops/msgs`),
    getCampaigns: ({
        bizId,
        startDate,
        endDate,
        campaignIds,
    }): Promise<Campaigns> =>
        req.get(`/businesses/${bizId}/adops/campaigns`, {
            start_date: startDate,
            end_date: endDate,
            campaign_ids: campaignIds.join(','),
            offset: 0,
            limit: 10000,
        }),
    getBusinessCampaigns: ({ bizId }) =>
        req.get(`/businesses/${bizId}/campaigns`),
    getAdGroups: ({
        bizId,
        startDate,
        endDate,
        campaignIds,
        adGroupIds,
    }): Promise<AdGroups> =>
        req.get(`/businesses/${bizId}/adops/adgroups`, {
            start_date: startDate,
            end_date: endDate,
            campaign_ids: campaignIds.join(','),
            adgroup_ids: adGroupIds.join(','),
            offset: 0,
            limit: 10000,
        }),
    getAds: ({
        bizId,
        startDate,
        endDate,
        adGroupIds,
        campaignIds,
        adIds,
        offset,
        limit,
    }): Promise<Ads> =>
        req.get(`/businesses/${bizId}/adops/ads`, {
            start_date: startDate,
            end_date: endDate,
            adgroup_ids: adGroupIds.join(','),
            campaign_ids: campaignIds.join(','),
            ad_ids: adIds.join(','),
            offset,
            limit,
        }),
    getAdAccountCampaigns: ({ bizId, adAccountId }) =>
        req.get(`/businesses/${bizId}/adaccounts/${adAccountId}/campaigns`),
    getRules: (
        { bizId },
        {
            adAccountId,
            channel,
            targetType,
            targetId,
            targetName,
            status,
            changeType,
            startDate,
            endDate,
        },
    ) =>
        req.get(`/businesses/${bizId}/rules`, {
            ad_account_id: adAccountId,
            channel,
            target_type: targetType,
            target_id: targetId,
            target_name: targetName,
            status,
            change_type: changeType,
            start_date: startDate,
            end_date: endDate,
        }),
    deleteRule: ({ bizId, ruleId }) =>
        req.delete(`/businesses/${bizId}/rules/${ruleId}`),
    deleteRules: ({ bizId, ruleIds }) =>
        req.delete(`/businesses/${bizId}/rules`, {
            rule_ids: ruleIds.join(','),
        }),
    postRule: ({ bizId }, data: RulePostParam[]) =>
        req.post(`/businesses/${bizId}/rules`, data),
    putRule: ({ bizId, ruleId }, data: RuleUpdateParam) =>
        req.put(`/businesses/${bizId}/rules/${ruleId}`, data),
    getRuleLogs: ({ bizId }, { startDate, endDate, offset, limit }) =>
        req.get(`/businesses/${bizId}/rule_logs`, {
            start_date: startDate,
            end_date: endDate,
            offset,
            limit,
        }),
    getMediaCampaign: ({
        bizId,
        adAccountId,
        campaignId,
        subTarget = false,
        onlyOn = true,
    }): Promise<Campaign> =>
        req.get(
            `/businesses/${bizId}/adops/media/accounts/${adAccountId}/campaigns/${campaignId}`,
            { sub_target: subTarget, only_on: onlyOn },
        ),
    getMediaAdGroup: ({
        bizId,
        adAccountId,
        adGroupId,
        subTarget = false,
        onlyOn = true,
    }): Promise<AdGroup> =>
        req.get(
            `/businesses/${bizId}/adops/media/accounts/${adAccountId}/adgroups/${adGroupId}`,
            { sub_target: subTarget, only_on: onlyOn },
        ),
    getMediaAd: ({ bizId, adAccountId, adId }): Promise<Ad> =>
        req.get(
            `/businesses/${bizId}/adops/media/accounts/${adAccountId}/ads/${adId}`,
        ),
    patchPrismCampaigns: ({ bizId, adAccountId }): Promise<PulledResult> =>
        req.patch(
            `/businesses/${bizId}/adaccounts/${adAccountId}/prism_campaigns`,
        ),
}

export const BizmoneyApi = {
    getBizmoneyList: ({ bizId }) => req.get(`/businesses/${bizId}/bizmoney`),
    getBizmoneyChart: ({ bizId }) => req.get(`/businesses/${bizId}/bizmoneys`),
}

const ruleServerUrl = process.env.REACT_APP_RULE_SERVER_URL
export const RuleServer = {
    postRule: (data: RuleRequest) => req.post(ruleServerUrl + '/rules', data),
    putRule: (ruleId, data: RuleRequest) =>
        req.put(ruleServerUrl + '/rules/' + ruleId, data),
    deleteRule: ({ ruleId }) => req.delete(ruleServerUrl + '/rules/' + ruleId),
    postGroupRule: (data: RuleGroupRequest) =>
        req.post(ruleServerUrl + '/groups', data),
    deleteGroup: ({ groupId }) =>
        req.delete(ruleServerUrl + '/groups/' + groupId, {
            remove_rules: false,
        }),
    getRules: ({ bizId }) =>
        req.get(ruleServerUrl + '/rules', {
            service: SERVICE_NANE,
            biz_id: bizId,
        }),
    getRule: ({ ruleId }) => req.get(ruleServerUrl + '/rules/' + ruleId),
    getLogs: ({ bizId }) =>
        req.get(ruleServerUrl + '/logs', {
            service: SERVICE_NANE,
            biz_id: bizId,
        }),
}
