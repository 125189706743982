import * as R from 'ramda'
import { matched, randomStr } from '@madup-inc/utils'

export { default as debounce } from 'lodash.debounce'

export {
    parseSearchParams,
    serializeSearchParams,
    randomStr,
    matched,
    pathMatched,
    validateBiznum,
    classNames,
    oneOf,
    loadJs,
} from '@madup-inc/utils'

export const add = R.add
export const always = R.always
export const pick = R.pick
export const adjust = R.adjust
export const path = R.path
export const pathEq = R.pathEq
export const equals = R.equals
export const pipe = R.pipe
export const update = R.update
export const omit = R.omit
export const head = R.head
export const init = R.init
export const ifElse = R.ifElse
export const isNil = R.isNil
export const startsWith = R.startsWith
export const type = R.type
export const identity = R.identity
export const isEmpty = R.isEmpty
export const last = R.last
export const concat = R.concat
export const clone = R.clone
export const lt = R.lt
export const gt = R.gt
export const cond = R.cond
export const all = R.all
export const any = R.any
export const assoc = R.assoc
export const sort = R.sort
export const filter = R.filter
export const reject = R.reject
export const evolve = R.evolve
export const assocPath = R.assocPath
export const slice = R.slice
export const forEach = R.forEach
export const has = R.has
export const not = R.not
export const split = R.split
export const T = R.T
export const F = R.F
export const complement = R.complement
export const find = R.find
export const map = R.map
export const mapObjIndexed = R.mapObjIndexed
export const append = R.append
export const remove = R.remove
export const insert = R.insert
export const range = R.range
export const prop = R.prop
export const unless = R.unless
export const dropLast = R.dropLast
export const endsWith = R.endsWith
export const join = R.join
export const gte = R.gte
export const lte = R.lte
export const when = R.when
export const tail = R.tail
export const groupBy = R.groupBy
export const reverse = R.reverse
export const length = R.length
export const __ = R.__
export const flatten = R.flatten
export const includes = R.includes
export const uniq = R.uniq
export const uniqBy = R.uniqBy
export const reduce = R.reduce
export const max = R.max
export const propOr = R.propOr
export const sum = R.sum
export const mergeAll = R.mergeAll
export const curry = R.curry
export const sortBy = R.sortBy
export const compose = R.compose
export const trim = R.trim
export const propEq = R.propEq
export const nth = R.nth
export const replace = R.replace
export const multiply = R.multiply
export const differenceWith = R.differenceWith
export const difference = R.difference
export const values = R.values

export const toComma = (value: string | number | null | undefined) =>
    value
        ? Number(value).toLocaleString('ko-KR', {
              maximumSignificantDigits: 10,
          })
        : '0'

export const toNumber = (value: string) => +String(value).replace(/,/gi, '')

export const toTime = value =>
    value
        ? new Date(value * 1000).toISOString().replace(/T|\.\d+Z/gi, ' ')
        : null

// 옵션 만들기
export const getOptions = (items, nameKey = 'name', idKey = 'id') => {
    return items.reduce((acc, item) => {
        acc.push({
            title: `${item[nameKey]}/${item[idKey]}`,
            value: item[idKey],
        })
        return acc
    }, [])
}

// check char length (영어 1byte 한글 2byte)
export const checkCharByte = (onChange, maxLength) => (e, index, target) => {
    if (maxLength) {
        const charByte = getCharByte(e.target.value)

        if (charByte > maxLength) {
            return false
        }
    }

    onChange(e, index, target)
}

export const getCharByte = value => {
    const valueLength = value.length
    let rbyte = 0
    let one_char = ''

    for (let i = 0; i < valueLength; i++) {
        one_char = value.charAt(i)

        if (escape(one_char).length > 4) {
            rbyte += 2
        } else {
            rbyte++
        }
    }

    return rbyte
}

export const removeLastSlash = ifElse(endsWith('/'), init, identity)

export const isNotNil = complement(isNil)

export const v4 = () => {
    return randomStr().substring(1)
}

export const generateUUID = () => {
    return (
        v4() +
        v4() +
        '-' +
        v4() +
        '-' +
        v4() +
        '-' +
        v4() +
        '-' +
        v4() +
        v4() +
        v4()
    )
}

export const floor = (num: number) => Math.floor(num)

export const unitValue = ifElse(
    pipe(
        Number,
        floor,
        String,
        ifElse(startsWith('-'), pipe(length, lte(6)), pipe(length, lte(5))),
    ),
    pipe(Number, floor, String, dropLast(4), toComma, concat(__, '만')),
    toComma,
)

export function round(num, offset = 0) {
    const e = (num, p) => Number(num + 'e' + p)
    const pos = offset > 0 ? offset - 1 : offset
    return e(Math.round(e(num, pos)), -pos)
}

export const roundComma = (value, offset = 0, padZero = true) => {
    const result = toComma(round(value, offset))
    if (!padZero || !result.includes('.')) {
        return result
    }
    const [left, right] = result.split('.')

    if (offset - 1 === right.length) {
        return result
    }
    return left + '.' + right.padEnd(offset - 1, '0')
}

export const go = <T = any>(...args: any[]): T =>
    pipe(...args.slice(1))(args[0])

export const delay = (ms: number) =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve(undefined)
        }, ms)
    })

export const sortText = (a: string, b: string) => a.localeCompare(b)
export const sortNumber = (a: number, b: number) => a - b
export const sortBoolean = (a: boolean, b: boolean) => {
    if (a && !b) {
        return -1
    } else if (!a && b) {
        return 1
    } else {
        return 0
    }
}

export const sortTextProp = prop => (a, b) => sortText(a[prop], b[prop])
export const sortNumberProp = prop => (a, b) => sortNumber(a[prop], b[prop])
export const sortBooleanProp = prop => (a, b) => sortBoolean(a[prop], b[prop])

export const isString = pipe(type, equals('String'))
export const isNumber = pipe(type, equals('Number'))
export const isBoolean = pipe(type, equals('Boolean'))
export const isDate = value => value instanceof Date

export const sortProp = prop => (a, b) => {
    if (isDate(a[prop]) && isDate(b[prop])) {
        return sortNumber(a[prop].getTime(), b[prop].getTime())
    }
    if (isString(a[prop]) && isString(b[prop])) {
        return sortTextProp(prop)(a, b)
    }
    if (isNumber(a[prop]) && isNumber(b[prop])) {
        return sortNumberProp(prop)(a, b)
    }
    if (isBoolean(a[prop]) && isBoolean(b[prop])) {
        return sortBooleanProp(prop)(a, b)
    }
    if (isNil(a[prop]) && isNotNil(b[prop])) {
        return -1
    } else if (isNotNil(a[prop]) && isNil(b[prop])) {
        return 1
    } else if (a[prop] === b[prop]) {
        return 0
    } else if (a[prop] === null && b[prop] === undefined) {
        return 1
    } else if (a[prop] === undefined && b[prop] === null) {
        return -1
    }

    throw Error(`Not supported type a=${a}, b=${b}`)
}

export const sortPropDesc = prop => (a, b) => sortProp(prop)(b, a)

// export const downloadUrl = async (url: string, filename: string) => {
//   // 크로스도메인 파일 다운로드 가능!
//   const response = await axios({
//     url,
//     method: 'GET',
//     responseType: 'blob',
//   })
//   downloadData(response.data, filename)
// }

export const downloadData = (data, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    downloadFile(url, filename)
}

export const downloadFile = (url: string, filename: string) => {
    // blob 데이터 또는 동일출처 url만 사용 가능
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename) // or any other extension
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const noop = () => {}

export const condition = (preds, defaultFn) => value =>
    typeof defaultFn === 'function' && preds.every(pred => !pred[0](value))
        ? defaultFn(value)
        : cond(preds)(value)

export const partial2nd: any = fn => (first, second, third) => {
    if (third === undefined) {
        return value => fn(first, value, second)
    }
    return fn(first, second, third)
}

export const gtelte = partial2nd(
    (min, value, max) => gte(value, min) && lte(value, max),
)
export const gtlte = partial2nd(
    (min, value, max) => gt(value, min) && lte(value, max),
)
export const gtelt = partial2nd(
    (min, value, max) => gte(value, min) && lt(value, max),
)
export const gtlt = partial2nd(
    (min, value, max) => gt(value, min) && lt(value, max),
)

export const omitProto = obj => {
    /*
     * 주의)
     *  obj 가 순환참조 객체일 경우 `RangeError: Maximum call stack size exceeded` 오류 발생!
     * 하지만, 해당 오류에 대한 핸들링이 별도로 필요할 것 같지는 않음
     * */

    if (
        !obj ||
        typeof obj !== 'object' ||
        typeof obj['__proto__'] !== 'object'
    ) {
        return obj
    }
    const cloned = clone(obj)
    Object.entries(cloned).forEach(([key, value]) => {
        cloned[key] = omitProto(value)
    })

    return Array.isArray(cloned)
        ? cloned
        : Object.assign(Object.create(null), cloned)
}

export const propNotEq = complement(propEq)

export const snakeToCamel = (value: string) =>
    value.replace(/_([a-zA-Z])/g, match => match[1].toUpperCase())

export const webScrap = async url => {
    const doc = await fetch(url)
        .then(res => res.text())
        .then(html => new DOMParser().parseFromString(html, 'text/html'))
    const title = doc.querySelector('head title')?.innerHTML
    const favicon = doc
        .querySelector('head link[rel="icon"]')
        ?.getAttribute('href')
    return { title, favicon: url + '/' + favicon }
}

export const capitalizeFirstChar = str =>
    str.charAt(0).toUpperCase() + str.slice(1)

export const peek =
    (...args) =>
    value => {
        if (!args.length) {
            window.$logger.debug('peek', value)
        } else {
            window.$logger.debug(...args, value)
        }
        return value
    }

export const buildStorage = storage => ({
    set(key, value) {
        if (value === undefined) {
            return
        }
        storage.setItem(key, value)
    },
    get(key) {
        return storage.getItem(key)
    },
    clear() {
        return storage.clear()
    },
    remove(key) {
        return storage.removeItem(key)
    },
})

export const sStorage = buildStorage(window.sessionStorage)
export const lStorage = buildStorage(window.localStorage)

export const upperFirstChar = str => str[0].toUpperCase() + str.slice(1)

export const findObjWithKeyValue = (...params) => {
    const [obj, targetKey, targetValue] = params

    if (type(obj) === 'Array') {
        for (let item of obj) {
            let result = findObjWithKeyValue(item, ...params.slice(1))
            if (result) {
                return result
            }
        }
    }

    if (type(obj) === 'Object') {
        for (let [key, value] of Object.entries(obj)) {
            if (matched(['Array', 'Object'], type(value))) {
                const result = findObjWithKeyValue(value, ...params.slice(1))
                if (result) {
                    return result
                }
            }
            if (
                key === targetKey &&
                (params.length === 3 ? value === targetValue : true)
            ) {
                return obj
            }
        }
    }

    return null
}

export const escapeRegExp = text => {
    return text.replace(/[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const highlight = (word, HIGHLIGHT_DELIMETER = ' ') => {
    return str => {
        if (!word) {
            return str
        }
        const regStr = word
            .split(HIGHLIGHT_DELIMETER)
            .filter(word => word !== '')
            .map(escapeRegExp)
            .join('|')
        const reg = new RegExp(`(${regStr})`, 'gi')
        return str.replace(reg, '<mark>$1</mark>')
    }
}

export const removeTag = (html: string): string => {
    if (html === undefined) {
        return ''
    }
    return html.replace(/(<([^>]+)>)/gi, '')
}

export const mark = (keyword: string) => (str: string) =>
    highlight(keyword)(removeTag(str))

export const lineNo = pipe(prop('stack'), split('\n'), nth(1), trim)

export const observeDom = (dom, callback) => {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return
            }
            callback(entry.target)
            observer.unobserve(entry.target)
        })
    })
    observer.observe(dom)
    return () => {
        observer.unobserve(dom)
    }
}

export const loadCss = src => {
    // element 추가
    const link: any = document.createElement('link')
    // href 어트리뷰트에 경로 추가
    link.href = src
    // 비동기식이 아닌 동기식으로 한다.
    link.async = false
    // style일의 기본 어트리뷰트 추가
    link.rel = 'stylesheet'
    link.type = 'text/css'
    // 헤더에 추가
    document.head.appendChild(link)
}
