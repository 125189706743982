import { Box } from '@mui/material'
import MainCard from 'ui-component/cards/MainCard'
import useDarkMode from 'zustands/useDarkMode'

export default function AuthCardWrapper({ children, ...other }) {
    const { darkMode } = useDarkMode()
    return (
        <MainCard
            sx={{
                backgroundColor: darkMode ? 'grey.700' : undefined,
                maxWidth: { xs: 400, lg: 475 },
                margin: { xs: 2.5, md: 3 },
                '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%',
                },
            }}
            content={false}
            {...other}
        >
            <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
        </MainCard>
    )
}
