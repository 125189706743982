import { QueryClient } from 'react-query'
import { TIME_MINUTE, TIME_SECOND } from 'constants/index'
import { matched } from 'utils/lib'

export const retry = (failureCount, error: any) =>
    (matched(
        [
            `This result object does not return rows. It has been closed automatically.`,
            `'NoneType' object has no attribute 'append'`,
        ],
        error.response?.data?.detail,
    ) ||
        error.response?.status === 429) &&
    failureCount < 2 // failureCount starts from 0

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retryDelay: TIME_SECOND * 2, // 2s
            retry,
            staleTime: TIME_MINUTE * 60 * 24, // cached for 24 hours
        },
    },
})

export default queryClient
