import create from 'zustand'
import { persist } from 'zustand/middleware'
import { DumbFunction } from '../types'
import { USER_ID_NAME } from 'constants/index'
import { lStorage } from 'utils/lib'

export interface State {
    userId: number
}

interface Store extends State {
    set: (param: State) => void
    clear: DumbFunction
}

export const config = set => {
    const localState = lStorage.get(USER_ID_NAME)
    return {
        userId: localState ? JSON.parse(localState).state.userId : 0,
        set,
        clear: () => set({}, true),
    }
}

const useUserId = create<Store>(
    persist(config, {
        name: USER_ID_NAME, // unique name
        getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }),
)

export default useUserId
