import { createElement, lazy } from 'react'
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: lazyElement(() => import('views/Sunset')),
        },
        {
            path: '/dashboard/default',
            element: lazyElement(() => import('views/dashboard/Default')),
        },
        {
            path: '/design-guide/util-typography',
            element: lazyElement(() => import('views/design-guide/Typography')),
        },
        {
            path: '/design-guide/util-color',
            element: lazyElement(() => import('views/design-guide/Color')),
        },
        {
            path: '/design-guide/util-shadow',
            element: lazyElement(() => import('views/design-guide/Shadow')),
        },
        {
            path: '/design-guide/util-breakpoint',
            element: lazyElement(() => import('views/design-guide/Breakpoint')),
        },
        {
            path: '/icons/tabler-icons',
            element: lazyElement(
                () => import('views/design-guide/TablerIcons'),
            ),
        },
        {
            path: '/icons/material-icons',
            element: lazyElement(
                () => import('views/design-guide/MaterialIcons'),
            ),
        },
        {
            path: '/basic/channels',
            element: lazyElement(() => import('views/basic/channels')),
        },
        {
            path: '/basic/money',
            element: lazyElement(() => import('views/basic/money')),
        },
        {
            path: '/basic/solution',
            element: lazyElement(() => import('views/basic/solution')),
        },
        {
            path: '/basic/channels',
            element: lazyElement(() => import('views/basic/channels')),
        },

        {
            path: '/basic/manage/ad-setting',
            element: lazyElement(() => import('views/basic/manage/ad-setting')),
        },
        {
            path: '/basic/manage/setting-history',
            element: lazyElement(
                () => import('views/basic/manage/setting-history'),
            ),
        },
        {
            path: '/basic/manage/manage-rule',
            element: lazyElement(
                () => import('views/basic/manage/manage-rule'),
            ),
        },
        {
            path: '/basic/large/build-creative',
            element: lazyElement(
                () => import('views/basic/large/build-creative'),
            ),
        },
        {
            path: '/basic/large/build-list',
            element: lazyElement(() => import('views/basic/large/build-list')),
        },
        {
            path: '/basic/extract/refresh',
            element: lazyElement(() => import('views/basic/extract/refresh')),
        },
        {
            path: '/basic/business-setting',
            element: lazyElement(
                () => import('views/basic/settings/business-setting'),
            ),
        },
        {
            path: '/basic/edit-business',
            element: lazyElement(
                () => import('views/basic/settings/edit-business'),
            ),
        },
        {
            path: '/basic/manage-member',
            element: lazyElement(
                () => import('views/basic/settings/manage-member'),
            ),
        },
        {
            path: '/basic/add-media',
            element: lazyElement(
                () => import('views/basic/channels/add-account'),
            ),
        },
        {
            path: '/design-guide/components/button-doc',
            element: lazyElement(
                () => import('views/design-guide/components/button-doc'),
            ),
        },
        {
            path: '/design-guide/components/table-doc',
            element: lazyElement(
                () => import('views/design-guide/components/table-doc'),
            ),
        },
        {
            path: '/design-guide/components/date-range-picker-doc',
            element: lazyElement(
                () =>
                    import(
                        'views/design-guide/components/date-range-picker-doc'
                    ),
            ),
        },
        {
            path: '/design-guide/components/date-range-picker-input-doc',
            element: lazyElement(
                () =>
                    import(
                        'views/design-guide/components/date-range-picker-input-doc'
                    ),
            ),
        },
        {
            path: '/design-guide/components/radio-doc',
            element: lazyElement(
                () => import('views/design-guide/components/radio-doc'),
            ),
        },
        {
            path: '/design-guide/components/select-doc',
            element: lazyElement(
                () => import('views/design-guide/components/select-doc'),
            ),
        },
        {
            path: '/design-guide/components/multi-select-doc',
            element: lazyElement(
                () => import('views/design-guide/components/multi-select-doc'),
            ),
        },
        {
            path: '/design-guide/components/date-picker-doc',
            element: lazyElement(
                () => import('views/design-guide/components/date-picker-doc'),
            ),
        },
        {
            path: '/design-guide/components/svg-icons-doc',
            element: lazyElement(
                () => import('views/design-guide/components/svg-icons-doc'),
            ),
        },
        {
            path: '/design-guide/components/checkbox-group-doc',
            element: lazyElement(
                () =>
                    import('views/design-guide/components/checkbox-group-doc'),
            ),
        },
        {
            path: '/design-guide/components/collapse-doc',
            element: lazyElement(
                () => import('views/design-guide/components/collapse-doc'),
            ),
        },
        {
            path: '/design-guide/components/popover-menu-doc',
            element: lazyElement(
                () => import('views/design-guide/components/popover-menu-doc'),
            ),
        },
        {
            path: '/design-guide/components/dialog-doc',
            element: lazyElement(
                () => import('views/design-guide/components/dialog-doc'),
            ),
        },
        {
            path: '/design-guide/components/box-list-doc',
            element: lazyElement(
                () => import('views/design-guide/components/box-list-doc'),
            ),
        },
        {
            path: '/design-guide/components/file-upload-doc',
            element: lazyElement(
                () => import('views/design-guide/components/file-upload-doc'),
            ),
        },
    ],
}

export default MainRoutes

function lazyElement(factory) {
    return createElement(Loadable(lazy(factory)))
}

/*
 * 자동으로 프로젝트 폴더 구조에 따라 라우팅 처리를 하고 싶었지만,
 * import 인자로 변수가 전달할 경우 기대했던 대로 동작하지 않음. 2022.5.31 Keating
 * */
// const routesByPathList = process.env.PATH_LIST!.split(',').map(path => ({
//     path,
//     element: lazyElement(() => import('views' + path)),
// }))
